import {createRouter, createWebHistory} from 'vue-router'
import qualityProblemRoutes from '@/views/home/quality/problem/problem.routes'
import governanceRoutes from '@/views/home/governance/governance.routes'
import systemRoutes from '@/views/home/system/system.routes'
import Layout from '@/views/layout'

let childList = [
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard'),
    name: 'Dashboard',
    meta: {title: '首页', nested: ['工作概览', '首页']}
  },
  {
    path: '/system/user',
    name: 'user',
    meta: {title: '用户管理', nested: ['系统管理', '用户管理']},
    component: () => import('@/views/home/system/user')
  },
  {
    path: '/rules/classification',
    name: 'classification',
    meta: {title: '预定义规则', nested: ['预定义规则管理', '预定义规则']},
    component: () => import('@/views/home/rules/classification')
  },
  {
    path: '/rules/module',
    name: 'module',
    meta: {title: '模板管理', nested: ['规则管理', '模板管理']},
    component: () => import('@/views/home/rules/module')
  },
  {
    path: '/collect/data_source',
    name: 'data_source',
    meta: {title: '数据源', nested: ['采集管理', '数据源']},
    component: () => import('@/views/home/collect/data_source'),
    children: []
  },
  {
    path: '/projects/project',
    name: 'my_project',
    meta: {title: '我的项目', nested: ['项目管理', '我的项目']},
    component: () => import('@/views/home/projects/project'),
    children: [
      {
        path: '/projects/project/projectWork',
        name: 'projectWork',
        meta: {
          title: '新增作业',
          nested: ['项目管理', '我的项目', '作业管理', '新增作业']
        },
        component: () => import('@/views/home/projects/projectWork')
      },
      {
        path: '/projects/project/projectDetails',
        name: 'projectDetails',
        meta: {
          title: '作业管理',
          nested: ['项目管理', '我的项目', '作业管理']
        },
        component: () => import('@/views/home/projects/projectDetails'),
        children: [
          {
            path: '/projects/project/projectDetails/workDetails',
            name: 'workDetails',
            meta: {
              title: '作业详情',
              nested: ['项目管理', '我的项目', '作业管理', '作业详情']
            },
            component: () => import('@/views/home/projects/workDetails')
          },
          {
            path: '/projects/project/projectDetails/workTask',
            name: 'workTask',
            meta: {
              title: '任务报表',
              nested: ['项目管理', '我的项目', '作业管理', '任务报表']
            },
            component: () => import('@/views/home/projects/workTask'),
            children: [
              {
                path: '/projects/project/projectDetails/workTask/viewLog',
                name: 'viewLog',
                meta: {
                  title: '任务日志',
                  nested: ['项目管理', '我的项目', '作业管理', '任务报表', '任务日志']
                },
                component: () => import('@/views/home/projects/viewLog')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/machineLearning/learning',
    name: 'learning',
    meta: {title: '机器学习', nested: ['项目管理', '机器学习']},
    component: () => import('@/views/home/machineLearning/learning'),
    children: [
      {
        path: '/machineLearning/dataIdentification',
        name: 'dataIdentification',
        meta: {
          title: '数据识别',
          nested: ['项目管理', '机器学习','数据识别']
        },
        component: () => import('@/views/home/machineLearning/dataIdentification')
      },
      {
        path: '/machineLearning/dataIdentificationResult',
        name: 'dataIdentificationResult',
        meta: {
          title: '结果',
          nested: ['项目管理', '机器学习','数据识别','结果']
        },
        component: () => import('@/views/home/machineLearning/dataIdentificationResult')
      },
      {
        path: '/machineLearning/dataAdd',
        name: 'dataAdd',
        meta: {
          title: '新建',
          nested: ['项目管理', '机器学习','新建']
        },
        component: () => import('@/views/home/machineLearning/dataAdd')
      },
      {
        path: '/machineLearning/qualityModel',
        name: 'qualityModel',
        meta: {
          title: '质量模型',
          nested: ['项目管理', '机器学习','质量模型']
        },
        component: () => import('@/views/home/machineLearning/qualityModel')
      },
      {
        path: '/machineLearning/dataModelAdd',
        name: 'dataModelAdd',
        meta: {
          title: '新建模型',
          nested: ['项目管理', '机器学习','新建模型']
        },
        component: () => import('@/views/home/machineLearning/dataModelAdd')
      },{
        path: '/machineLearning/modelTraining',
        name: 'modelTraining',
        meta: {
          title: '模型训练',
          nested: ['项目管理', '机器学习','模型训练']
        },
        component: () => import('@/views/home/machineLearning/modelTraining')
      },{
        path: '/machineLearning/supportAlgorithm',
        name: 'supportAlgorithm',
        meta: {
          title: '支撑算法',
          nested: ['项目管理', '机器学习','支撑算法']
        },
        component: () => import('@/views/home/machineLearning/supportAlgorithm')
      },
    ]
  },
  {
    path: '/task/taskMonitor',
    name: 'taskMonitor',
    meta: {title: '任务报表', nested: ['任务监控', '任务报表']},
    component: () => import('@/views/home/task/taskMonitor')
  },
  {
    path: '/report/reportExport',
    name: 'reportExport',
    meta: {title: '项目报告', nested: ['报告管理', '项目报告']},
    component: () => import('@/views/home/report/reportExport'),
    children: [
      {
        path: '/report/workExport',
        name: 'workExport',
        meta: {
          title: '作业报告',
          nested: ['报告管理', '项目报告', '作业报告']
        },
        component: () => import('@/views/home/report/workExport')
      }
    ]
  },
  // {
  //   path: '/quality/report/evaluating',
  //   name: 'evaluatingReport',
  //   meta: { title: '评估管理', nested: ['数据质量', '评估报告'] },
  //   component: () => import('@/views/home/quality/report/evaluating'),
  // },
  // {
  //   path: '/quality/report/quality',
  //   name: 'qualityReport',
  //   meta: { title: '报告管理', nested: ['数据质量', '质量报告'] },
  //   component: () => import('@/views/home/quality/report/quality'),
  // },
  {
    path: '/knowledge/list',
    name: 'knowledgeList',
    meta: {title: '知识库列表', nested: ['知识库', '知识清单']},
    component: () => import('@/views/home/quality/knowledge')
  },
  {
    path: '/knowledge/category',
    name: 'knowledgeCategory',
    meta: {title: '知识分类', nested: ['知识库', '知识分类']},
    component: () => import('@/views/home/quality/knowledge/category')
  },
  {
    path: '/issueTicket/list',
    name: 'issueTicket',
    meta: {title: '工单列表', nested: ['工单管理', '工单列表']},
    component: () => import('@/views/home/quality/ticket')
  },
  {
    path: '/myIssueTicket',
    name: 'myIssueTicket',
    meta: {title: '我的工单', nested: ['工单管理', '我的工单']},
    component: () => import('@/views/home/quality/ticket/module/my-ticket')
  },
  {
    path: '/infoQueryService',
    name: 'infoQueryService',
    meta: {title: '信息查询', nested: ['数据服务', '信息查询']},
    component: () => import('@/views/home/quality/service/module/info-query-service')
  },
  {
    path: '/dataServiceList',
    name: 'dataServiceList',
    meta: {title: '信息查询', nested: ['数据服务', '服务清单']},
    component: () => import('@/views/home/quality/service/module/info-data-service-list')
  },
  ...qualityProblemRoutes,
  ...governanceRoutes,
  ...systemRoutes
]

function getAllComponent(childList, all) {
  childList.forEach(function (row) {
    all.push(row)
    if (row.children) {
      getAllComponent(row.children, all)
    }
  })
  return all
}

export const routes = [
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noRedirect',
    children: [
      {
        path: 'center',
        component: () => import('@/views/home/system/user/center'),
        name: 'PersonalCenter',
        meta: {title: '个人中心', nested: ['个人中心']}
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noRedirect',
    children: [
      {
        path: 'alert',
        component: () => import('@/views/home/system/user/alert'),
        name: 'MyAlert',
        meta: {title: '我的告警', nested: ['我的告警']}
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/features/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/features/401'),
    hidden: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/features/redirect')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: getAllComponent(childList, [])
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...routes]
})

export default router
