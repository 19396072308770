import {getInfo, login, logout} from '@/api/login'
import {getToken, removeToken, setToken} from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        user: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USER: (state, user) => {
            state.user = user
        }
    },

    actions: {
        Login({commit}, userInfo) {
            const rememberMe = userInfo.rememberMe

            return new Promise((resolve, reject) => {
                login(
                    userInfo.username,
                    userInfo.password,
                    userInfo.code,
                    userInfo.uuid
                )
                    .then(res => {
                        setToken(res.data.token, rememberMe)
                        commit('SET_TOKEN', res.data.token)
                        setUserInfo(res.data.user, commit)
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        GetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then(res => {
                        setUserInfo(res.data, commit)
                        resolve(res)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        LogOut({commit}) {
            return new Promise((resolve, reject) => {
                logout()
                    .then(() => {
                        logOut(commit)
                        resolve()
                    })
                    .catch(error => {
                        logOut(commit)
                        reject(error)
                    })
            })
        }
    },
    getters: {}
}

export const logOut = commit => {
    commit('SET_TOKEN', '')
    removeToken()
}

export const setUserInfo = (u, commit) => {
    commit('SET_USER', u)
}

export default user
