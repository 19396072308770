<template>
  <div class="graph full_window">
    <div class="graph_page">
      <div class="tool_bar">
        <div class="button_group">
          <div class="operation">
            <el-button round size="small" type="primary" @click="operation">
              <el-icon :size="18">
                <i-caret-right />
              </el-icon>
              运行
            </el-button>
            <el-tooltip class="item" content="保存" effect="light" placement="bottom">
              <el-button circle size="medium" type="info" @click="save_graph_data">
                <svg-icon class="icon" icon-class="save" />
              </el-button>
            </el-tooltip>
          </div>
          <div class="button_group">
            <div class="compile">
              <el-tooltip class="item" content="删除选中" effect="light" placement="bottom">
                <el-button circle size="medium" @click="delete_cell">
                  <el-icon :size="15">
                    <i-delete />
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" content="重做" effect="light" placement="bottom">
                <el-button circle size="medium" @click="redo">
                  <el-icon :size="15">
                    <i-refresh-right />
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" content="撤销" effect="light" placement="bottom">
                <el-button circle size="medium" @click="undo">
                  <el-icon :size="15">
                    <i-refresh-left />
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" content="导出png" effect="light" placement="bottom">
                <el-button circle size="medium" @click="download_png">
                  <el-icon :size="15">
                    <i-picture />
                  </el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <div class="button_group">
            <div class="fold">
              <el-tooltip class="item" content="折叠全部节点" effect="light" placement="bottom">
                <el-button circle size="medium" @click="all_cell_fold">
                  <el-icon :size="15">
                    <i-caret-top />
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" content="展开全部节点" effect="light" placement="bottom">
                <el-button circle size="medium" @click="all_cell_unfold">
                  <el-icon :size="15">
                    <i-caret-bottom />
                  </el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <el-tooltip class="item" content="放大" effect="light" placement="bottom">
            <el-button circle size="medium" @click="zoom_in">
              <el-icon :size="15">
                <i-zoom-in />
              </el-icon>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" content="缩小" effect="light" placement="bottom">
            <el-button circle size="medium" @click="zoom_out">
              <el-icon :size="15">
                <i-zoom-out />
              </el-icon>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="graph_ele_box">
        <div class="graph_item_box">
          <div class="title_box">
            <div class="table_title">
              <P class="first_class_tittle"> 流程编辑 </P>
            </div>
            <el-tooltip class="item" content="帮助" effect="light" placement="bottom">
              <el-button circle size="small" type="text" @click="graph_help_open">
                <svg-icon class="title_icon_help" icon-class="help" />
              </el-button>
            </el-tooltip>
          </div>

          <el-tree ref="link_tree" :data="link_data" :props="defaultProps" node-key="id" @mousedown.ctrl="drag_cell($event)"></el-tree>
        </div>
      </div>
      <div class="graph_box">
        <div id="container"></div>
      </div>
      <div v-if="message_box" class="message_box">
        <div class="message_box_inline">
          <p>节点类型</p>
          <el-input v-model="type"></el-input>
          <p>节点ID</p>
          <el-input v-model="id"></el-input>
          <p>x坐标</p>
          <el-input v-model="x"></el-input>
          <p>y坐标</p>
          <el-input v-model="y"></el-input>
        </div>
      </div>
      <div v-show="context_menu" class="context_menu" style="top: 0px; left: 0px">
        <div class="context_menu_btn">
          <el-button icon="el-icon-delete" type="text" @click="delete_node">
            <el-icon :size="15" class="tool_bar_icon">
              <i-delete />
            </el-icon>
            删除
          </el-button>
        </div>
      </div>
    </div>
  </div>

  <!-- 帮助弹窗 -->
  <el-dialog v-model="graph_help" :before-close="handleClose" title="编辑器帮助" width="30%">
    <div class="help_inline">
      <span>del键可以对已选中的节点进行删除</span>
      <span>shift+鼠标左键可以对画布进行拖拽</span>
      <span>alt+鼠标滚轮可以对画布进行缩放</span>
    </div>
  </el-dialog>
</template>
<script>
import '@antv/x6-vue-shape'
import Database from '@/assets/img/graph_icon/Database.png'
import { Addon, DataUri, Graph, Shape } from '@antv/x6'
import { h } from 'vue'

export default {
  data() {
    return {
      //连线样式
      edge_attrs: {
        line: {
          stroke: '#1890ff',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
          strokeDasharray: 0, //虚线
          style: {
            animation: 'ant-line 30s infinite linear',
          },
        },
      },
      //错误连线样式
      error_attrs: {
        line: {
          stroke: '#cc0033',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },

          strokeDasharray: 0, //虚线
          style: {
            animation: 'ant-line 30s infinite linear',
          },
        },
      },
      // 存储out、in连线
      in_dege: '',
      out_edge: '',
      //数据写死时判断拖拽datqa
      start_num: 0,
      // graph_el:false,
      //treedata也是graph拖拽data
      link_data: [
        {
          id: 1,
          label: '数据源',
          children: [
            {
              id: 2,
              label: '表',
              children: [
                {
                  id: 3,
                  label: 'cs_send',
                  children: [
                    {
                      id: 4,
                      label: 'djjg',
                      type: 'mysql',
                      size: 20,
                    },
                    {
                      id: 5,
                      label: 'dml_sequence',
                      type: 'mysql',
                      size: 20,
                    },
                    {
                      id: 6,
                      label: 'dml_type',
                      type: 'mysql',
                      size: 20,
                    },
                    {
                      id: 7,
                      label: 'fddbr',
                      type: 'mysql',
                      size: 20,
                    },
                  ],
                },
              ],
            },
            {
              id: 8,
              label: '视图',
              children: [
                {
                  id: 9,
                  label: 'cs_recv',
                  children: [
                    {
                      id: 10,
                      label: 'jyfw',
                      type: 'oracle',
                      size: 20,
                    },
                    {
                      id: 11,
                      label: 'receive_date',
                      type: 'oracle',
                      size: 10,
                    },
                    {
                      id: 12,
                      label: 'fddbr',
                      type: 'oracle',
                      size: 30,
                    },
                    {
                      id: 13,
                      label: 'recv_ts',
                      type: 'oracle',
                      size: 10,
                    },
                    {
                      id: 14,
                      label: 'dml_type',
                      type: 'oracle',
                      size: 30,
                    },
                    {
                      id: 15,
                      label: 'sid',
                      type: 'oracle',
                      size: 20,
                    },
                    {
                      id: 16,
                      label: 'djjg',
                      type: 'oracle',
                      size: 20,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      //设置规则

      options: [
        {
          value: '成功',
          label: '成功',
        },
        {
          value: '失败',
          label: '失败',
        },
        {
          value: '自定义',
          label: '自定义',
        },
      ],
      custom_rule_inline: false,
      return_rule: '',
      custom_rules: '',
      line_type: 2,
      //graph点击右键储存cell内容
      contextmenu_cell: '',
      id: '',
      type: '',
      x: '',
      y: '',
      //连线类型
      connect_edge_type: 'normal',
      router_edge_type: {
        name: 'er',
        args: {
          offset: 25,
          direction: 'H',
        },
      },
      context_menu: false,
      graph_help: false,
      //选中节点右侧出现详情栏
      message_box: false,
      // activeNames: ["1"],
      //graph数据node节点信息、edge链接线信息
      data: {
        // graph: "",
        // // 节点
        // nodes: [],
        // // 边
        // edges: [],
      },
    }
  },
  mounted: function () {
    this.get_graph_data()
    this.nodes_graph()
  },
  methods: {
    //初始化画布==========================================================================================================================
    nodes_graph() {
      var _that = this
      // new Graph不是function所以this无法指向到data,只能指向到函数内部,使用data需要使用_that存下data
      this.graph = new Graph({
        container: document.getElementById('container'),
        //默认值是undefined，会获取容器的值
        width: undefined,
        height: 680,
        //对齐线
        // snapline: true,
        //允许在画布中使用键盘
        keyboard: true,
        //按下crtl可以使用鼠标滚轮对画布进行缩放
        mousewheel: {
          enabled: true,
          modifiers: ['ctrl'],
        },
        //按下shift时允许画布拖拽，不设置modifiers会与框选操作冲突
        panning: {
          enabled: true,
          modifiers: 'shift',
        },
        //使用撤销和重做需要优先开启history
        history: {
          enabled: true,
          // // ignoreChange: true,
          // beforeAddCommand(event, args) {
          //   console.log(event)
          //   console.log(args)
          //   if (args.options) {
          //     return args.options.ignore !== false
          //   }
          //   if(args.cell == 'Rect'){
          //     return args.options.ignore == false
          //   }
          // },
        },
        selecting: {
          enabled: true, //是否允许点选
          rubberband: true, //是否允许框选
          // showNodeSelectionBox: true, //显示node选中边框
          // showEdgeSelectionBox: true, //显示框选边框\
          className: 'x6-widget-selection-rubberband',
        },
        connecting: {
          //是否允许连接到空白处
          allowBlank: false,
          //相同的起点和终点之间是否允许连接多条连线
          allowMulti: true,
          //是否允许连接到节点而非链桩上
          allowNode: false,
          //创建连线
          createEdge() {
            return new Shape.Edge({
              attrs: _that.edge_attrs,
              label: {
                text: '',
              },
              //路由设置
              connector: _that.connect_edge_type,
              router: _that.router_edge_type,
              zIndex: 0,
            })
          },
          //连线结束时根据判断连线
          validateEdge({ edge }) {
            // console.log(edge.toJSON())
            const edge_source_port_num = edge.toJSON().source.port.substring(4) * 1 + 1
            const edge_target_port_num = edge.toJSON().target.port.substring(4) * 1 + 1
            const source = _that.graph.getCellById(edge.toJSON().source.cell).toJSON()
            const target = _that.graph.getCellById(edge.toJSON().target.cell).toJSON()
            //设置连线规则out只能连in链桩，如果输出链桩size小于输入链桩size连线出现叹号
            if (edge.source.port == 'out' && edge.target.port == 'in') {
              return true
            } else if (edge.source.port != 'out' && edge.target.port != 'in') {
              if (source.ports.items[edge_source_port_num].size >= target.ports.items[edge_target_port_num].size) {
                return true
              } else {
                //通过addedge的方法建立不同的连线
                const new_edge = new Shape.Edge({
                  attrs: _that.error_attrs,
                  label: '!',
                  source: edge.source,
                  target: edge.target,
                  connector: _that.connect_edge_type,
                  router: _that.router_edge_type,
                })
                _that.graph.addEdge(new_edge)
                return false
              }
            }
          },
        },
        background: {
          color: '#ffffff', // 设置画布背景颜色
        },
        grid: {
          size: 10, // 网格大小 10px
          visible: true, // 渲染网格背景
        },
      })
      //按data中的数据加载cell
      this.graph.fromJSON(this.data)
      //单击节点回调----------------------------------------------------------------------------------------------------------------------
      // this.graph.on("cell:click", ({ x, y, cell }) => {
      //   // console.log(cell.toJSON())
      //   // this.message_box = true;
      //   // if (cell.isNode()) {
      //   //   this.type = cell.attrs.title.text;
      //   //   this.id = cell.id;
      //   //   this.x = x;
      //   //   this.y = y;
      //   // } else {
      //   //   this.type = "连接线";
      //   //   this.id = cell.id;
      //   //   this.x = x;
      //   //   this.y = y;
      //   // }
      //   // console.log(x)
      //   // console.log(y)
      //   // console.log(cell)
      // });
      //按del删除cell-----------------------------------------------------------------------------------------------------------------
      this.graph.bindKey('del', () => {
        this.delete_cell()
      })
      //边单独选中-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:selected', args => {
        args.cell.attr('line/strokeDasharray', 5)
      })
      //边取消选中-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:unselected', args => {
        args.cell.attr('line/strokeDasharray', 0)
      })
      //点击空白位置回调-----------------------------------------------------------------------------------------------------------------
      this.graph.on('blank:click', () => {
        this.message_box = false
        this.context_menu = false
      })
      //鼠标右键点击回调-----------------------------------------------------------------------------------------------------------------
      this.graph.on('cell:contextmenu', ({ x, y, cell }) => {
        this.context_menu = true
        const _x = x + 370
        const _y = y - 50
        document.getElementsByClassName('context_menu')[0].setAttribute('style', 'top:' + _y + 'px;' + 'left:' + _x + 'px')
        this.contextmenu_cell = cell
        //重制cell的选中状态
        this.graph.resetSelection(cell)
      })
      //点选cell显示节点toolbutton和边框-----------------------------------------------------------------------------------------------------------------
      this.graph.on('cell:selected', ({ cell }) => {
        if (cell.isNode()) {
          cell.addTools([
            {
              name: 'boundary',
              args: {
                attrs: {
                  fill: '#7c68fc',
                  stroke: '#909399',
                  'stroke-width': 1,
                  'fill-opacity': 0.05,
                },
              },
            },
            {
              name: 'button',
              args: {
                markup: [
                  {
                    tagName: 'circle',
                    selector: 'button',
                    attrs: {
                      r: 9,
                      stroke: '#5F95FF',
                      strokeWidth: 2,
                      fill: 'white',
                      cursor: 'pointer',
                    },
                  },
                  {
                    tagName: 'text',
                    textContent: '-',
                    selector: 'icon',
                    attrs: {
                      fill: '#fe854f',
                      fontSize: 30,
                      textAnchor: 'middle',
                      textVerticalAnchor: 'middle',
                      pointerEvents: 'none',
                      y: '0.25em',
                    },
                  },
                ],
                x: 183,
                y: 5,
                //折叠celltool按钮点击
                onClick({ cell }) {
                  if (cell.size().height != 60) {
                    _that.fold_edge(cell)
                    _that.fold_cell(cell)
                  } else if (cell.size().height == 60) {
                    _that.unfold_edge(cell)
                    _that.unfold_cell(cell)
                  }
                },
              },
            },
          ])
        } else {
          cell.addTools(['vertices', 'segments'])
        }
      })
      //取消选中时移除tool
      this.graph.on('cell:unselected', ({ cell }) => {
        cell.removeTools()
      })
      //链桩链接时匹配关系添加edge-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:connected', ({ isNew, edge }) => {
        if (isNew) {
          //判断链桩是不是out链桩
          if (edge.toJSON().source.port == 'out') {
            //获取连线两端节点
            const source = this.graph.getCellById(edge.toJSON().source.cell).toJSON()
            const target = this.graph.getCellById(edge.toJSON().target.cell).toJSON()
            // 对两端节点的ports进行比对
            source.ports.items.forEach(item => {
              // console.log(item)
              if (item.name != undefined) {
                let i = 0
                //比对并添加edge
                for (i; i < target.ports.items.length; i++) {
                  if (item.name == target.ports.items[i].name) {
                    if (item.size >= target.ports.items[i].size) {
                      const edge = new Shape.Edge({
                        attrs: _that.edge_attrs,
                        source: { cell: source.id, port: item.group },
                        target: { cell: target.id, port: target.ports.items[i].group },
                        connector: _that.connect_edge_type,
                        router: _that.router_edge_type,
                      })
                      this.graph.addEdge(edge)
                    } else {
                      const edge = new Shape.Edge({
                        attrs: _that.error_attrs,
                        label: '!',
                        source: { cell: source.id, port: item.group },
                        target: { cell: target.id, port: target.ports.items[i].group },
                        connector: _that.connect_edge_type,
                        router: _that.router_edge_type,
                      })
                      this.graph.addEdge(edge)
                    }
                  }
                }
              }
            })
            this.$notify({
              title: '完成匹配',
              type: 'success',
              message: h('i', { style: 'color: teal' }, '完成自动匹配'),
            })
            //建立连接edge前如果cell是折叠状态更改链桩链接位置
            const source_cell = this.graph.getCellById(edge.toJSON().source.cell)
            const target_cell = this.graph.getCellById(edge.toJSON().target.cell)
            if (source_cell.size().height == 60) {
              const cell = this.graph.getCellById(edge.toJSON().source.cell)
              // 更改链桩的连线位置
              _that.fold_edge(cell)
            }
            if (target_cell.size().height == 60) {
              const cell = this.graph.getCellById(edge.toJSON().target.cell)
              // 更改链桩的连线位置
              _that.fold_edge(cell)
            }
          }
        }
      })
      //out、in链桩edge删除时删除列链桩==========================================================================================================================
      this.graph.on('edge:removed', edge => {
        console.log(edge)
        const source = this.graph.getCellById(edge.edge.source.cell)
        //先删除cell触发edge removed会导致getOutgoingEdges的回调是null
        if (this.graph.getOutgoingEdges(source)) {
          const outedge_exist = this.graph.getOutgoingEdges(source)
          if (edge.edge.source.port == 'out') {
            if (outedge_exist) {
              outedge_exist.forEach(item => {
                this.graph.removeEdge(item)
              })
            }
          }
        }
      })
    },
    //link tree拖拽==========================================================================================================================
    drag_cell(e) {
      //获取tree点击节点----------------------------------------------------------------------------------------------------------
      //  console.log(e.target)
      //根据id去tree中查找节点数据(测试数据目前写死，需要通过e.target值去后滩或者遍历获取id)
      if (this.start_num == 0) {
        this.$refs.link_tree.setCurrentNode({ id: 3 })
        this.start_num++
      } else if (this.start_num == 1) {
        this.$refs.link_tree.setCurrentNode({ id: 9 })
        this.start_num = 0
      }
      //  this.$refs.link_tree.setCurrentNode({id:3})
      //需要先通过.setCurrentNode选取一个节点才能通过getCurrentNode获取节点内容
      const current_node = JSON.parse(JSON.stringify(this.$refs.link_tree.getCurrentNode()))
      //  console.log(current_node)
      //设置自动匹配链桩
      const link_ports = {
        groups: {
          left: {
            position: [0, 30],
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: '#2D8CF0',
                strokeWidth: 2,
                fill: '#fff',
              },
            },
          },
          right: {
            position: [180, 30],
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: '#2D8CF0',
                strokeWidth: 2,
                fill: '#fff',
              },
            },
          },
        },
        items: [
          {
            id: 'in',
            group: 'left',
          },
          {
            id: 'out',
            group: 'right',
          },
        ],
      }
      //设置列链桩----------------------------------------------------------------------------------------------------------
      const list_num = current_node.children.length
      let ports_num = 1
      for (ports_num; ports_num <= list_num; ports_num++) {
        const groups_key = `list${ports_num}`
        const ports_y = ports_num * 24 + 35
        //  console.log(current_node.children[ports_num-1].label)
        link_ports.groups[groups_key] = {
          markup: [
            {
              tagName: 'rect',
              selector: 'portBody',
            },
            {
              tagName: 'text',
              selector: 'portNameLabel',
            },
            {
              tagName: 'text',
              selector: 'porttype',
            },
            {
              tagName: 'text',
              selector: 'portsize',
            },
          ],
          attrs: {
            portBody: {
              width: 180,
              height: 24,
              strokeWidth: 1,
              stroke: '#5F95FF',
              fill: '#EFF4FF',
              magnet: true,
            },
            portNameLabel: {
              text: current_node.children[ports_num - 1].label,
              ref: 'portBody',
              refX: 6,
              refY: 6,
              fontSize: 10,
            },
            porttype: {
              text: current_node.children[ports_num - 1].type,
              ref: 'portBody',
              refX: 90,
              refY: 6,
              fontSize: 10,
            },
            portsize: {
              text: current_node.children[ports_num - 1].size,
              ref: 'portBody',
              refX: 160,
              refY: 6,
              fontSize: 10,
            },
          },
          //链桩定位
          position: [0, ports_y],
        }
        link_ports.items.push({
          id: `list${ports_num}`,
          group: `list${ports_num}`,
          name: current_node.children[ports_num - 1].label,
          type: current_node.children[ports_num - 1].type,
          size: current_node.children[ports_num - 1].size,
        })
      }
      //  console.log(link_ports)
      //设置拖拽节点----------------------------------------------------------------------------------------------------------
      let link_node = this.graph.createNode({
        ports: link_ports,
        width: 180,
        height: list_num * 24 + 55,
        attrs: {
          body: {
            rx: 4,
            ry: 4,
            stroke: '#5F95FF',
            strokeWidth: 1,
            fill: 'rgba(95,149,255,0.05)',
          },
          image: {
            href: Database,
            width: 35,
            height: 35,
            x: 12,
            y: 12,
          },
          title: {
            text: '表',
            refX: 55,
            refY: 14,
            fill: 'rgba(0,0,0,0.85)',
            fontSize: 12,
            'text-anchor': 'start',
          },
          text: {
            text: current_node.label,
            refX: 55,
            refY: 38,
            fontSize: 12,
            fill: 'rgba(0,0,0,0.6)',
            'text-anchor': 'start',
          },
        },
        markup: [
          {
            tagName: 'rect',
            selector: 'body',
          },
          {
            tagName: 'image',
            selector: 'image',
          },
          {
            tagName: 'text',
            selector: 'title',
          },
          {
            tagName: 'text',
            selector: 'text',
          },
        ],
        //定义一个自定义按钮
      })
      //创建一个dnd拖拽实例(必须是target格式)
      const dnd = new Addon.Dnd({ target: this.graph })
      //鼠标按下时开始拖拽
      dnd.start(link_node, e)
    },

    //右键删除==========================================================================================================================
    delete_node() {
      //removeCells变量必须是list格式不然无效
      this.graph.removeCells([this.contextmenu_cell])
      this.context_menu = false
      this.type = 'grid'
    },
    //批量删除按钮==========================================================================================================================
    delete_cell() {
      const cell = this.graph.getSelectedCells()
      this.graph.removeCells(cell)
      this.context_menu = false
      this.type = 'grid'
    },
    //放大==========================================================================================================================
    zoom_in() {
      this.graph.zoom(0.2)
    },
    //缩小==========================================================================================================================
    zoom_out() {
      this.graph.zoom(-0.2)
    },
    //运行向后台传值==========================================================================================================================
    operation() {
      //需要将graph序列化获得node和edge的数据
      console.log(this.graph.toJSON())
      const cells = this.graph.toJSON()
      const node_list = []
      const edge_list = []
      let begin_node_id = ''
      //确定开始节点
      cells.cells.forEach(element => {
        if (element.shape == 'edge') {
          edge_list.push(element)
        } else {
          node_list.push(element)
        }
      })
      if (edge_list.length > 0) {
        const target_list = []
        edge_list.forEach(element => {
          target_list.push(element.target.cell)
        })
        node_list.forEach(element => {
          if (target_list.indexOf(element.id) < 0) {
            begin_node_id = element.id
          }
        })
      }
      console.log(begin_node_id)
      this.$notify({
        title: '开始运行',
        type: 'success',
        message: h('i', { style: 'color: teal' }, '流程text1开始运行'),
      })
    },
    //导出graph到png==========================================================================================================================
    download_png() {
      //等待下一次渲染执行结束后调用
      this.$nextTick(() => {
        this.graph.toPNG(
          dataUri => {
            // 下载
            DataUri.downloadDataUri(dataUri, '流程图.png')
          },
          {
            backgroundColor: 'white',
            padding: {
              top: 50,
              right: 50,
              bottom: 50,
              left: 50,
            },
            quality: 1,
            copyStyles: false,
          },
        )
      })
    },
    //全部未折叠cell==========================================================================================================================
    all_cell_fold() {
      const all_cells = this.graph.toJSON()
      if (all_cells.cells.length > 0) {
        all_cells.cells.forEach(item => {
          if (item.shape == 'rect') {
            if (item.size.height != 60) {
              this.fold_edge(item)
              this.fold_cell(item)
            }
          }
        })
      }
    },
    //全部展开未展开的cell==========================================================================================================================
    all_cell_unfold() {
      const all_cells = this.graph.toJSON()
      if (all_cells.cells.length > 0) {
        all_cells.cells.forEach(item => {
          if (item.shape == 'rect') {
            if (item.size.height == 60) {
              this.unfold_edge(item)
              this.unfold_cell(item)
            }
          }
        })
      }
    },
    //折叠cell时让edge改变连结链桩==========================================================================================================================
    fold_edge(c) {
      const flot_port = {
        in_port: [],
        out_port: [],
      }
      //cell的out链桩和in链桩
      const outedge_exist = this.graph.getOutgoingEdges(c)
      const inedge_exist = this.graph.getIncomingEdges(c)
      //需要重新获取下cell不然cell中的propties会让setData()报错
      const cell = this.graph.getCellById(c.id)
      if (outedge_exist) {
        this.graph.getOutgoingEdges(c).forEach(item => {
          if (item.source.port == 'out') {
            this.out_edge = item.toJSON().source
          } else {
            flot_port.out_port.push(item.toJSON())
            item.setSource(this.out_edge)
          }
          //把折叠之前连线链接的位置存在cell的data属性中
          //data属性中只能储存一个变量set第二个变量会覆盖第一个变量
        })
        cell.setData(flot_port)
      }
      if (inedge_exist) {
        this.graph.getIncomingEdges(c).forEach(item => {
          if (item.target.port == 'in') {
            this.in_edge = item.toJSON().target
          } else {
            flot_port.in_port.push(item.toJSON())
            item.setTarget(this.in_edge)
          }
          //把折叠之前连线链接的位置存在cell的data属性中
          //data属性中只能储存一个变量set第二个变量会覆盖第一个变量
        })
        cell.setData(flot_port)
      }
    },
    //折叠cell高度隐藏链桩==========================================================================================================================
    fold_cell(c) {
      const cell = this.graph.getCellById(c.id)
      const get_port_num = cell.getPorts().length - 2
      //更改链桩的高度和链桩text的字号 隐藏链桩组
      for (let i = 1; i <= get_port_num; i++) {
        cell.setPortProp('list' + i, ['attrs', 'portBody'], { height: 0 })
        cell.setPortProp('list' + i, ['attrs', 'portNameLabel'], { fontSize: 0 })
        cell.setPortProp('list' + i, ['attrs', 'porttype'], { fontSize: 0 })
        cell.setPortProp('list' + i, ['attrs', 'portsize'], { fontSize: 0 })
      }
      cell.size({ width: 180, height: 60 })
      //调用更改链桩位置的方法
    },
    //展开cell时让edge改变连结链桩==========================================================================================================================
    unfold_edge(c) {
      const cell = this.graph.getCellById(c.id)
      if (cell.data) {
        if (cell.data.in_port.length > 0) {
          const get_port_list = Object.values(cell.data.in_port)
          for (let i = 0; i < get_port_list.length; i++) {
            if (this.graph.getIncomingEdges(cell)) {
              this.graph.getIncomingEdges(cell).forEach(item => {
                if (get_port_list[i].id == item.toJSON().id) {
                  item.setTarget(get_port_list[i].target)
                }
              })
            }
          }
        }
        if (cell.data.out_port.length > 0) {
          const get_port_list = Object.values(cell.data.out_port)
          for (let i = 0; i < get_port_list.length; i++) {
            if (this.graph.getOutgoingEdges(cell)) {
              this.graph.getOutgoingEdges(cell).forEach(item => {
                if (get_port_list[i].id == item.toJSON().id) {
                  item.setSource(get_port_list[i].source)
                }
              })
            }
          }
        }
      }
    },
    //展开cell高度显示链桩==========================================================================================================================
    unfold_cell(c) {
      const cell = this.graph.getCellById(c.id)
      const get_port_num = cell.getPorts().length - 2
      //更改链桩的高度和链桩text的字号 显示链桩组
      for (let i = 1; i <= get_port_num; i++) {
        cell.setPortProp('list' + i, ['attrs', 'portBody'], { height: 24 })
        cell.setPortProp('list' + i, ['attrs', 'portNameLabel'], { fontSize: 10 })
        cell.setPortProp('list' + i, ['attrs', 'porttype'], { fontSize: 10 })
        cell.setPortProp('list' + i, ['attrs', 'portsize'], { fontSize: 10 })
      }
      cell.size({ width: 180, height: 62 })
    },
    //画布重做==========================================================================================================================
    redo() {
      this.graph.redo()
    },
    //画布撤销==========================================================================================================================
    undo() {
      this.graph.undo()
    },
    //保存graph数据==========================================================================================================================
    save_graph_data() {
      this.$store.commit('graph_data/change_graph_data', this.graph.toJSON())
      this.$notify({
        title: '保存成功',
        type: 'success',
        message: h('i', { style: 'color: teal' }, '流程图text1保存成功'),
      })
    },
    //帮助弹窗==========================================================================================================================
    graph_help_open() {
      this.graph_help = true
    },
    //加载graph_data==========================================================================================================================
    get_graph_data() {
      // console.log('===============================================')
      // console.log(this.$route.query.type)
      // console.log('===============================================')
      if (this.$route.query.type != undefined) {
        this.$route.query.type == 'created' ? (this.data = JSON.parse(JSON.stringify(this.$store.state.graph_data.graph_data))) : ''
      }
    },
  },
}
</script>
<style scoped>
.graph {
  display: flex;
  flex-direction: column;

  height: calc(100vh - 50px);
  overflow: hidden;
}

.graph_page {
  display: flex;
  justify-content: center;
  position: relative;
}

.message_box {
  position: absolute;
  z-index: 1000;
  width: 20%;
  height: 100%;
  background-color: #ffffff;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 5px #d1d1d1;
  /* display: none; */
}

.graph_box {
  width: 80%;
  height: calc(100vh - 77px);
}

.context_menu {
  position: absolute;
  z-index: 2000;
  width: 150px;
  /* height: 200px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #d1d1d1;
}

.context_menu_btn {
  width: 94%;
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  border-bottom: 1px solid #d1d1d157;
  margin-left: 3%;
  margin-right: 3%;
}

.context_menu_btn > button {
  text-align: start;
  margin-left: 0px !important;
  line-height: 14px;
  font-size: 14px;
  color: #909399 !important;
}

.message_box_inline {
  margin: 10px;
}

.message_box_inline > p {
  width: 100%;
  text-align: start;
  margin: 10px;
}

.graph_ele_box {
  width: 19%;
  height: calc(100vh - 50px);
  box-shadow: 3px 3px 5px #d1d1d1;
  z-index: 100;
}

.graph_item_box {
  margin: 0px 10px 0px 10px;
}

.graph_element {
  display: flex;
  justify-content: flex-start;
  padding-top: 25px;
}

.graph_element_part {
  width: 82px;
  display: flex;
  flex-direction: column;
  margin: 0px 15px 0px 15px;
  justify-content: center;
  align-items: center;
}

.element_size {
  width: 50px;
}

.tool_bar {
  position: absolute;
  top: 15px;
  left: 21%;
  z-index: 10;
  background-color: #ffffff;
  /* width: 30%; */
  display: flex;
  border-radius: 5px;
  justify-content: center;
  box-shadow: 0px 0px 5px #d1d1d1;
}

.tool_bar > .button_group {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
}

.tool_bar > button {
  margin: 10px 10px 10px 0px;
}

.graph_edge_part {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 7px 15px;
  border-radius: 4px;
}

.graph_edge_part > p {
  text-align: center;
}

.line_type {
  background-color: #9093992e;
}

.tool_bar_icon {
  margin-right: 10px;
}

.operation {
  /* width: 90px; */
  margin-right: 16px;
  position: relative;
}

.operation::after {
  content: '';
  position: absolute;
  border-right: 1px solid #90939966;
  left: 131px;
  top: 5px;
  height: 30px;
}

.compile {
  margin-right: 20px;
  position: relative;
}

.compile::after {
  content: '';
  position: absolute;
  border-right: 1px solid #90939966;
  left: 187px;
  top: 5px;
  height: 30px;
}

.fold {
  margin-right: 20px;
  position: relative;
}

.fold::after {
  content: '';
  position: absolute;
  border-right: 1px solid #90939966;
  left: 94px;
  top: 5px;
  height: 30px;
}

.el-select {
  width: 100%;
}

.rules_box {
  margin-top: -30px;
}

.icon {
  font-size: 13px;
}

.title_box {
  margin: 8px 0px 3px 0px;
  display: flex;
  justify-content: space-between;
}

.title_icon_help {
  font-size: 22px;
}

.help_inline {
  display: flex;
  flex-direction: column;
}

.help_inline > span {
  margin-bottom: 5px;
}
</style>
