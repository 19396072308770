<template>
  <el-dialog v-model="dialogAlarmReason.isShow" title="告警规则详情" width="80%" fullscreen>
    <el-scrollbar>
      <el-descriptions border class="margin-top" column="5" direction="vertical" size="small" title="规则配置">
        <el-descriptions-item align="center" label="告警原因" label-align="left" span="5">
          {{ dialogAlarmReason.row.alarmReason }}
        </el-descriptions-item>
        <el-descriptions-item align="center" label="告警时间" label-align="left">
          {{ dialogAlarmReason.row.alarmTime }}
        </el-descriptions-item>
        <el-descriptions-item align="center" label="校验对象" label-align="left">
          <span v-for="item in selectedData.CheckObjectEnum" v-show="dialogAlarmReason.row.checkObject === item.CODE" :key="item.CODE" v-text="item.LABEL" />
        </el-descriptions-item>
        <el-descriptions-item align="center" label="校验模板" label-align="left">
          <span v-for="item in selectedData.CheckTemplateEnum" v-show="dialogAlarmReason.row.checkTemplate === item.CODE" :key="item.CODE" v-text="item.LABEL" />
        </el-descriptions-item>
        <el-descriptions-item align="center" label="校验条件" label-align="left">
          <span v-for="item in selectedData.CompareTypeEnum" v-show="dialogAlarmReason.row.compareType === item.CODE" :key="item.CODE" v-text="item.LABEL" />
        </el-descriptions-item>
        <el-descriptions-item align="center" label="阈值" label-align="left">
          <span v-if="dialogAlarmReason.row.checkTemplate === 4" v-text="dialogAlarmReason.row.threshold" />
          <span v-else v-text="dialogAlarmReason.row.threshold + '%'" />
        </el-descriptions-item>
      </el-descriptions>
      <br />
      <el-descriptions border class="margin-top" column="2" direction="vertical" size="small" title="任务结果">
        <el-descriptions-item align="center" label="平均值(模板为：日波动、月波动、周波动才进行计算平均值)" label-align="left" width="150px">
          {{ dialogAlarmReason.row.average }}
        </el-descriptions-item>
        <el-descriptions-item align="center" label="任务结果(总量数据/优质数据/问题数据)" label-align="left" width="150px">
          {{ dialogAlarmReason.row.result }}
        </el-descriptions-item>
      </el-descriptions>
      <br />
      <el-descriptions border class="margin-top" column="2" direction="vertical" size="small" title="校验结果">
        <el-descriptions-item align="center" label="计算公式" label-align="left" span="2" width="150px">
          {{ dialogAlarmReason.row.formula }}
        </el-descriptions-item>
        <el-descriptions-item align="center" label="校验结果" label-align="left" span="2" width="150px">
          <el-tag v-if="dialogAlarmReason.row.isPass === 1" type="success"> 通过校验 </el-tag>
          <el-tag v-else> 未通过校验 </el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <br />
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { alarmFormSelected } from '@/api/project/work'

export default {
  name: 'TaskAlarmReasonDialog',
  data() {
    return {
      selectedData: null,
      dialogAlarmReason: {
        isShow: false,
        row: {},
      },
    }
  },
  mounted() {
    this.loadSelectedData()
  },
  methods: {
    async loadSelectedData() {
      const { data, code } = await alarmFormSelected()
      if (code === 200) this.selectedData = data
    },
  },
}
</script>

<style scoped></style>
