const parseTime = function parseTime(time, cFormat) {
    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'

    let date

    if (typeof time === 'undefined' || time === null || time === 'null') {
      return ''
    } else if (typeof time === 'object') {
      date = time
    } else {
      if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
        time = parseInt(time)
      }
      if (typeof time === 'number' && time.toString().length === 10) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      },
      time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday

        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })

    return time_str
  },
  downloadFile = function downloadFile(obj, name, suffix) {
    const url = window.URL.createObjectURL(new Blob([obj])),
      link = document.createElement('a')

    link.style.display = 'none'
    link.href = url
    const fileName = parseTime(new Date()) + '-' + name + '.' + suffix

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  formatTime = function formatTime(d) {
    let dTime = []

    for (let item of d) {
      if (typeof item !== 'string') {
        dTime.push(item.getFullYear() + '-' + (item.getMonth() + 1) + '-' + item.getDate() + ' ' + item.getHours() + ':' + item.getMinutes() + ':' + item.getSeconds())
      } else {
        dTime.push(item)
      }
    }
    return dTime
  }
const transElIconName = function transElIconName(iconName) {
  return 'i' + iconName.replace(/[A-Z]/g, match => '-' + match.toLowerCase())
}
const shortcuts = () => [
  {
    text: '上周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '上个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '最近三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start.getTime(), end.getTime()]
    },
  },
]
const timeDifference = (startTime, endTime) => {
  let start = typeof startTime == 'number' ? startTime : new Date(startTime).getTime(),
    end = typeof endTime == 'number' ? endTime : new Date(endTime).getTime(),
    difference = end - start, //时间差的毫秒数
    days = Math.floor(difference / (24 * 3600 * 1000)), //计算出相差天数
    leave1 = difference % (24 * 3600 * 1000), //计算天数后剩余的毫秒数
    hours = Math.floor(leave1 / (3600 * 1000)), //计算相差分钟数
    leave2 = leave1 % (3600 * 1000), //计算小时数后剩余的毫秒数
    minutes = Math.floor(leave2 / (60 * 1000)), //计算相差秒数
    leave3 = leave2 % (60 * 1000), //计算分钟数后剩余的毫秒数
    seconds = Math.round(leave3 / 1000)
  return `${days}天${hours}小时${minutes}分钟${seconds}秒`
}

const getDateDiff = (startTime, endTime) => {
  let sTime = new Date(startTime)
  let eTime = new Date(endTime)
  if ((eTime.getTime() - sTime.getTime()) / 1000 < 60) {
    return parseInt(((eTime.getTime() - sTime.getTime()) / 1000).toString()) + '秒'
  } else if ((eTime.getTime() - sTime.getTime()) / (1000 * 60) <= 60) {
    return parseInt(((eTime.getTime() - sTime.getTime()) / (1000 * 60)).toString()) + '分钟'
  } else if ((eTime.getTime() - sTime.getTime()) / (1000 * 3600) <= 60) {
    return parseInt(((eTime.getTime() - sTime.getTime()) / (1000 * 3600)).toString()) + '小时'
  } else {
    return parseInt(((eTime.getTime() - sTime.getTime()) / (1000 * 3600 * 24)).toString()) + '天'
  }
}
const compareTime = (startTime, endTIme) => {
  let beginTime = startTime
  let endTime = endTIme
  let beginTimes = beginTime.substring(0, 10).split('-')
  let endTimes = endTime.substring(0, 10).split('-')
  beginTime = beginTimes[1] + '-' + beginTimes[2] + '-' + beginTimes[0] + ' ' + beginTime.substring(10, 19)
  endTime = endTimes[1] + '-' + endTimes[2] + '-' + endTimes[0] + ' ' + endTime.substring(10, 19)
  return (Date.parse(endTime) - Date.parse(beginTime)) / 3600 / 1000
}
export default {
  formatTime,
  downloadFile,
  parseTime,
  transElIconName,
  shortcuts,
  getDateDiff,
  timeDifference,
  compareTime,
}
