import {DELETE, GET, GET_SER, POST} from '@/utils/api.js'

export function myAlarmTree() {
    return GET('/api/v1/core/project/alarm/tree')
}

export function myAlarmList(params) {
    return GET_SER('/api/v1/core/project/alarm/list', params)
}

export function ignoreWarning(id) {
    return POST('/api/v1/core/project/alarm/ignoreWarning/' + id)
}

export function myAlarmCount() {
    return GET('/api/v1/core/project/alarm/myAlarmCount')
}

export function deleteWarning(id) {
    return DELETE('/api/v1/core/project/alarm/' + id)
}
