import SockJS from 'sockjs-client'
import {getToken} from '../../utils/auth'
import {Client} from '@stomp/stompjs'

const state = {
        taskRunData: '',
        taskEndpointClient: ''
    },
    mutations = {
        SET_TASK_RUN_DATA: (state, data) => {
            state.taskRunData = data
        },
        SET_TASK_ENDPOINT_CLIENT: (state, data) => {
            state.taskEndpointClient = data
        },
        CLOSE_TASK_ENDPOINT_CLIENT: state => {
            closeConnection(state.taskEndpointClient)
        }
    },
    actions = {
        SubscribeTaskStatusChannel: ({commit}) => {
            const client = getDefaultConfigClient(
                process.env.VUE_APP_BASE_API + '/ws/stomp/taskEndpoint'
            )
            client.onConnect = () => {
                client.subscribe('/user/queue/errors', res => {
                    console.log(JSON.parse(res.body))
                })
                client.subscribe('/user/single/run.status', res => {
                    commit('SET_TASK_RUN_DATA', JSON.parse(res.body))
                })
            }
            client.onStompError = function (frame) {
                console.log(
                    'Broker reported error: ' + frame.headers['message'],
                    'Additional details: ' + frame.body
                )
            }
            client.activate()
            commit('SET_TASK_ENDPOINT_CLIENT', client)
        },
        Deactivate: ({commit}) => {
            commit('CLOSE_TASK_ENDPOINT_CLIENT')
        }
    },
    getters = {
        taskRunData: state => state.taskRunData
    }

function getDefaultConfigClient(url) {
    const client = new Client({
        brokerURL: '',
        connectHeaders: {Authorization: getToken()},
        debug: () => {
        },
        reconnectDelay: 10000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
    })
    setWebSocketFactory(client, url)
    return client
}

// eslint-disable-next-line no-unused-vars
function publish(client, body) {
    if (client != null) {
        client.publish({
            destination: '',
            body
        })
    }
}

function closeConnection(client) {
    if (client != null) {
        client.deactivate()
    }
}

function setWebSocketFactory(client, endpoint) {
    client.webSocketFactory = function () {
        return new SockJS(endpoint)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
