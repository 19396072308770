export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function validURL(url) {
    const reg =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}

export function validLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

export function validUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

export function validAlphabets(str) {
    const reg = /^[A-Za-z]+$/
    return reg.test(str)
}

export function isvalidPhone(phone) {
    const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
    return reg.test(phone)
}

export function isString(str) {
    if (typeof str === 'string' || str instanceof String) {
        return true
    }
    return false
}

export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}

export function validateIP(rule, value, callback) {
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        const reg =
            /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        if (!reg.test(value) && value !== '') {
            callback(new Error('请输入正确的IP地址'))
        } else {
            callback()
        }
    }
}

export function validatePhoneTwo(rule, value, callback) {
    const reg =
        /^((0\d{2,3}-\d{7,8})|(1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}))$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if (!reg.test(value) && value !== '') {
            callback(new Error('请输入正确的电话号码或者固话号码'))
        } else {
            callback()
        }
    }
}

export function validateTelephone(rule, value, callback) {
    const reg = /0\d{2}-\d{7,8}/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if (!reg.test(value) && value !== '') {
            callback(new Error('请输入正确的固话（格式：区号+号码,如010-1234567）'))
        } else {
            callback()
        }
    }
}

export function validatePhone(rule, value, callback) {
    const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if (!reg.test(value) && value !== '') {
            callback(new Error('请输入正确的电话号码'))
        } else {
            callback()
        }
    }
}

export function validateIdNo(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if (!reg.test(value) && value !== '') {
            callback(new Error('请输入正确的身份证号码'))
        } else {
            callback()
        }
    }
}
