import ProblemListIndex from '../problem/list/index/problem-list-index'
import ProblemSummaryIndex from '../problem/summary/index/problem-summary-index'

const routes = [
  {
    path: '/quality/problem/list/index',
    name: 'problem',
    meta: { title: '问题清单', nested: ['质量问题', '问题清单'] },
    component: ProblemListIndex,
  },
  {
    path: '/quality/problem/summary/index',
    name: 'problemSummary',
    meta: { title: '问题总结', nested: ['质量问题', '问题总结'] },
    component: ProblemSummaryIndex,
  },
]

export default routes
