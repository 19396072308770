import { DELETE, GET_SER, PUT } from '@/utils/api'

export function addIssueSummaryApi(data) {
  return PUT('/api/v1/core/project/issueSummary', data)
}

export function issueSummaryListApi(data) {
  return GET_SER('/api/v1/core/project/issueSummary/list', data)
}

export function issueSummaryDeleteApi(id) {
  return DELETE(`/api/v1/core/project/issueSummary/${id}`)
}
