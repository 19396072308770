module.exports = {
  title: '数据质量平台',
  platformName: '数据质量平台',
  tagsView: true,
  fixedHeader: true,
  tokenCookieExpires: 1, //token在cookie中存储的天数 默认1天
  passCookieExpires: 1, //密码在cookie中存储的天数 默认1天
  uniqueOpened: true,
  TokenKey: 'GOVERNANCE-TOKEN',
  timeout: 10000,
  sidebarLogo: true,
  showFooter: true,
  pageSize: 5,
}
