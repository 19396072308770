import defaultSettings from '@/settings'

const {title, uniqueOpened, platformName, pageSize} = defaultSettings,
    state = {
        title: title,
        platformName: platformName,
        uniqueOpened: uniqueOpened,
        pageSize
    },
    mutations = {},
    actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
