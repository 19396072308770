<template>
  <div class="graph full_window">
    <div class="graph_page">
      <div class="tool_bar">
        <div class="button_group">
          <div class="operation">
            <el-button size="small" type="primary" round @click="operation">
              <el-icon :size="18"> <i-caret-right /> </el-icon>
              运行
            </el-button>
            <el-tooltip class="item" effect="light" content="保存" placement="bottom">
              <el-button type="info" size="medium" circle @click="save_graph_data"> <svg-icon icon-class="save" class="icon" /> </el-button>
            </el-tooltip>
          </div>
          <div class="button_group">
            <div class="compile">
              <el-tooltip class="item" effect="light" content="删除选中" placement="bottom">
                <el-button size="medium" circle @click="delete_cell">
                  <el-icon :size="15"> <i-delete /> </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="重做" placement="bottom">
                <el-button size="medium" circle @click="redo">
                  <el-icon :size="15"> <i-refresh-right /> </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="撤销" placement="bottom">
                <el-button size="medium" circle @click="undo">
                  <el-icon :size="15"> <i-refresh-left /> </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="导出png" placement="bottom">
                <el-button size="medium" circle @click="download_png">
                  <el-icon :size="15"> <i-picture /> </el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <el-tooltip class="item" effect="light" content="放大" placement="bottom">
            <el-button size="medium" circle @click="zoom_in">
              <el-icon :size="15"> <i-zoom-in /> </el-icon>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="缩小" placement="bottom">
            <el-button size="medium" circle @click="zoom_out">
              <el-icon :size="15"> <i-zoom-out /> </el-icon>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="graph_ele_box">
        <div class="graph_item_box">
          <div class="title_box">
            <div class="table_title"><P class="first_class_tittle"> 治理规则 </P></div>
            <el-tooltip class="item" effect="light" content="帮助" placement="bottom">
              <el-button size="small" type="text" circle @click="graph_help_open"> <svg-icon icon-class="help" class="title_icon_help" /> </el-button>
            </el-tooltip>
          </div>

          <el-collapse v-model="activeNames" @change="handleChange($event)">
            <el-collapse-item title="选择规则拖拽到画布" name="1">
              <div class="graph_element">
                <div class="graph_element_part" @mousedown="start_drag('database', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>主键检测</p>
                </div>
                <div class="graph_element_part" @mousedown="start_drag('server', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>枚举值域检测</p>
                </div>
              </div>
              <div class="graph_element">
                <div class="graph_element_part" @mousedown="start_drag('sharing', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>空值检测</p>
                </div>
                <div class="graph_element_part" @mousedown="start_drag('sharing', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>唯一性检测</p>
                </div>
              </div>
              <div class="graph_element">
                <div class="graph_element_part" @mousedown="start_drag('sharing', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>正则表达式检测</p>
                </div>
                <div class="graph_element_part" @mousedown="start_drag('sharing', $event)">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/Sharing.png" />
                  <p>标准值域检测</p>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="选择链接线类型" name="2">
              <div class="graph_element">
                <div class="graph_edge_part" :class="[line_type == 1 ? 'line_type' : '']" @click="change_edge_type('normal')">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/line.png" />
                  <p>直线</p>
                </div>
                <div class="graph_edge_part" :class="[line_type == 2 ? 'line_type' : '']" @click="change_edge_type('rounded')">
                  <img class="element_size" src="../../../../../assets/img/graph_icon/curve.png" />
                  <p>曲线</p>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="graph_box"><div id="container"></div></div>
      <div v-if="message_box" class="message_box">
        <div class="message_box_inline">
          <p>节点类型</p>
          <el-input v-model="type"></el-input>
          <p>节点ID</p>
          <el-input v-model="id"></el-input>
          <p>x坐标</p>
          <el-input v-model="x"></el-input>
          <p>y坐标</p>
          <el-input v-model="y"></el-input>
        </div>
      </div>
      <div v-show="context_menu" style="top: 0px; left: 0px" class="context_menu">
        <div class="context_menu_btn">
          <el-button type="text" icon="el-icon-delete" @click="delete_node">
            <el-icon class="tool_bar_icon" :size="15"> <i-delete /> </el-icon>
            删除
          </el-button>
          <el-button type="text" icon="el-icon-delete" @click="change_edge_link">
            <el-icon class="tool_bar_icon" :size="16"> <i-link /> </el-icon>
            设置关系
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <!-- 连线规则设置弹窗 -->
  <el-dialog title="规则选择" v-model="selection_rule" width="40%">
    <div class="rules_box">
      <el-divider content-position="left">选择规则</el-divider>
      <el-select v-model="return_rule" placeholder="请选择规则" @change="rule_search">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div v-show="custom_rule_inline">
        <el-divider content-position="left">填写自定义规则</el-divider>
        <el-input v-model="custom_rules" placeholder="请输入内容"></el-input>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="selection_rules()">确 定</el-button>
        <el-button @click="selection_rule = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 帮助弹窗 -->
  <el-dialog title="编辑器帮助" v-model="graph_help" width="30%" :before-close="handleClose">
    <div class="help_inline"><span>del键可以对已选中的节点进行删除</span> <span>shift+鼠标左键可以对画布进行拖拽</span> <span>ctrl+鼠标滚轮可以对画布进行缩放</span></div>
  </el-dialog>
</template>
<script>
import '@antv/x6-vue-shape';
import Database from '@/assets/img/graph_icon/Database.png';
import Server from '@/assets/img/graph_icon/Server.png';
import Sharing from '@/assets/img/graph_icon/Sharing.png';
import { Graph, Addon, Shape, DataUri } from '@antv/x6';
import { h } from 'vue';

export default {
  data() {
    return {
      //设置规则
      selection_rule: false,
      options: [
        {
          value: '成功',
          label: '成功'
        },
        {
          value: '失败',
          label: '失败'
        },
        {
          value: '自定义',
          label: '自定义'
        }
      ],
      custom_rule_inline: false,
      return_rule: '',
      custom_rules: '',
      line_type: 2,
      //graph点击右键储存cell内容
      contextmenu_cell: '',
      id: '',
      type: '',
      x: '',
      y: '',
      //连线类型
      connect_edge_type: 'rounded',
      router_edge_type: 'manhattan',
      context_menu: false,
      graph_help: false,
      //选中节点右侧出现详情栏
      message_box: false,
      activeNames: ['1'],
      //graph数据node节点信息、edge链接线信息
      data: {
        // graph: "",
        // // 节点
        // nodes: [],
        // // 边
        // edges: [],
      }
    };
  },
  mounted: function() {
    this.get_graph_data();
    this.nodes_graph();
  },
  methods: {
    //初始化画布==========================================================================================================================
    nodes_graph() {
      var _that = this;
      // new Graph不是function所以this无法指向到data,只能指向到函数内部,使用data需要使用_that存下data
      this.graph = new Graph({
        container: document.getElementById('container'),
        //默认值是undefined，会获取容器的值
        width: undefined,
        height: 680,
        snapline: true,
        //允许在画布中使用键盘
        keyboard: true,
        //按下crtl可以使用鼠标滚轮对画布进行缩放
        mousewheel: {
          enabled: true,
          modifiers: ['ctrl']
        },
        //按下shift时允许画布拖拽，不设置modifiers会与框选操作冲突
        panning: {
          enabled: true,
          modifiers: 'shift'
        },
        //使用撤销和重做需要优先开启history
        history: true,
        selecting: {
          enabled: true, //是否允许点选
          rubberband: true, //是否允许框选
          showNodeSelectionBox: true, //显示node选中边框
          // showEdgeSelectionBox: true, //显示框选边框\
          className: 'x6-widget-selection-rubberband'
        },
        connecting: {
          //是否允许连接到空白处
          allowBlank: false,
          //相同的起点和终点之间是否允许连接多条连线
          allowMulti: true,
          //是否允许连接到节点而非链桩上
          allowNode: false,
          createEdge() {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: '#1890ff',
                  strokeWidth: 1,
                  targetMarker: {
                    name: 'classic',
                    size: 8
                  },
                  strokeDasharray: 0, //虚线
                  style: {
                    animation: 'ant-line 30s infinite linear'
                  }
                }
              },
              label: {
                text: ''
              },
              connector: _that.connect_edge_type,
              router: {
                name: _that.router_edge_type
              },
              zIndex: 0
            });
          }
        },
        background: {
          color: '#ffffff' // 设置画布背景颜色
        },
        grid: {
          size: 10, // 网格大小 10px
          visible: true // 渲染网格背景
        }
      });
      this.graph.fromJSON(this.data);
      //单击节点回调----------------------------------------------------------------------------------------------------------------------
      this.graph.on('cell:click', ({ x, y, cell }) => {
        this.message_box = true;
        if (cell.isNode()) {
          this.type = cell.attrs.title.text;
          this.id = cell.id;
          this.x = x;
          this.y = y;
        } else {
          this.type = '连接线';
          this.id = cell.id;
          this.x = x;
          this.y = y;
        }
      });
      this.graph.bindKey('del', () => {
        this.delete_cell();
      });
      //边单独选中-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:selected', (args) => {
        args.cell.attr('line/strokeDasharray', 5);
      });
      //边取消选中-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:unselected', (args) => {
        args.cell.attr('line/strokeDasharray', 0);
      });
      //点击空白位置回调-----------------------------------------------------------------------------------------------------------------
      this.graph.on('blank:click', () => {
        this.message_box = false;
        this.context_menu = false;
      });
      //鼠标右键点击回调-----------------------------------------------------------------------------------------------------------------
      this.graph.on('cell:contextmenu', ({ x, y, cell }) => {
        this.context_menu = true;
        const _x = x + 370;
        const _y = y - 50;
        document.getElementsByClassName('context_menu')[0].setAttribute('style', 'top:' + _y + 'px;' + 'left:' + _x + 'px');
        this.contextmenu_cell = cell;
        //重制cell的选中状态
        this.graph.resetSelection(cell);
      });

      //链桩链接时弹出关系输入-----------------------------------------------------------------------------------------------------------------
      this.graph.on('edge:connected', ({ isNew, edge }) => {
        if (isNew) {
          this.selection_rule = true;
          this.contextmenu_cell = edge;
          this.return_rule = '';
          this.custom_rules = '';
          this.custom_rule_inline = false;
        }
      });
    },

    //拖拽==========================================================================================================================
    start_drag(type, e) {
      console.log(e);
      //定义元件链桩
      const ports = {
        groups: {
          // 输入链接桩群组定义
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: '#2D8CF0',
                strokeWidth: 2,
                fill: '#fff'
              }
            }
          },
          // 输出链接桩群组定义
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: '#2D8CF0',
                strokeWidth: 2,
                fill: '#fff'
              }
            }
          }
          // left: {
          //   position: "left",
          //   attrs: {
          //     circle: {
          //       r: 4,
          //       magnet: true,
          //       stroke: "#2D8CF0",
          //       strokeWidth: 2,
          //       fill: "#fff",
          //     },
          //   },
          // },
          // right: {
          //   position: "right",
          //   attrs: {
          //     circle: {
          //       r: 4,
          //       magnet: true,
          //       stroke: "#2D8CF0",
          //       strokeWidth: 2,
          //       fill: "#fff",
          //     },
          //   },
          // },
        },
        items: [
          {
            id: 'port1',
            group: 'top'
          },
          {
            id: 'port2',
            group: 'bottom'
          }
          // {
          //   id: "port3",
          //   group: "left",
          // },
          // {
          //   id: "port4",
          //   group: "right",
          // },
        ]
      };
      //鼠标按下时获取event、建立节点node通过addon、dnd方法实现拖拽
      //鼠标按下时在graph中建立一个新的node节点
      let node = '';
      if (type == 'database') {
        node = this.graph.createNode({
          ports: ports,
          width: 300,
          height: 60,
          attrs: {
            body: {
              rx: 4,
              ry: 4,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: 'rgba(95,149,255,0.05)'
            },
            image: {
              href: Database,
              width: 35,
              height: 35,
              x: 12,
              y: 12
            },
            title: {
              text: '主键检测',
              refX: 55,
              refY: 14,
              fill: 'rgba(0,0,0,0.85)',
              fontSize: 12,
              'text-anchor': 'start'
            },
            text: {
              text: 'estar_supply_db',
              refX: 55,
              refY: 38,
              fontSize: 12,
              fill: 'rgba(0,0,0,0.6)',
              'text-anchor': 'start'
            }
          },
          markup: [
            {
              tagName: 'rect',
              selector: 'body'
            },
            {
              tagName: 'image',
              selector: 'image'
            },
            {
              tagName: 'text',
              selector: 'title'
            },
            {
              tagName: 'text',
              selector: 'text'
            }
          ]
        });
      } else if (type == 'server') {
        node = this.graph.createNode({
          ports: ports,
          width: 300,
          height: 60,
          attrs: {
            body: {
              rx: 4,
              ry: 4,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: 'rgba(95,149,255,0.05)'
            },
            image: {
              href: Server,
              width: 35,
              height: 35,
              x: 12,
              y: 12
            },
            title: {
              text: '枚举值检测',
              refX: 55,
              refY: 14,
              fill: 'rgba(0,0,0,0.85)',
              fontSize: 12,
              'text-anchor': 'start'
            },
            text: {
              text: 'estar_supply_db',
              refX: 55,
              refY: 38,
              fontSize: 12,
              fill: 'rgba(0,0,0,0.6)',
              'text-anchor': 'start'
            }
          },
          markup: [
            {
              tagName: 'rect',
              selector: 'body'
            },
            {
              tagName: 'image',
              selector: 'image'
            },
            {
              tagName: 'text',
              selector: 'title'
            },
            {
              tagName: 'text',
              selector: 'text'
            }
          ]
        });
      } else if (type == 'sharing') {
        node = this.graph.createNode({
          ports: ports,
          width: 300,
          height: 60,
          attrs: {
            body: {
              rx: 4,
              ry: 4,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: 'rgba(95,149,255,0.05)'
            },
            image: {
              href: Sharing,
              width: 35,
              height: 35,
              x: 12,
              y: 12
            },
            title: {
              text: '空值检测',
              refX: 55,
              refY: 14,
              fill: 'rgba(0,0,0,0.85)',
              fontSize: 12,
              'text-anchor': 'start'
            },
            text: {
              text: 'estar_supply_db',
              refX: 55,
              refY: 38,
              fontSize: 12,
              fill: 'rgba(0,0,0,0.6)',
              'text-anchor': 'start'
            }
          },
          markup: [
            {
              tagName: 'rect',
              selector: 'body'
            },
            {
              tagName: 'image',
              selector: 'image'
            },
            {
              tagName: 'text',
              selector: 'title'
            },
            {
              tagName: 'text',
              selector: 'text'
            }
          ]
        });
      }
      //创建一个dnd拖拽实例(必须是target格式)
      const dnd = new Addon.Dnd({ target: this.graph });
      //鼠标按下时开始拖拽
      dnd.start(node, e);
    },
    //右键删除==========================================================================================================================
    delete_node() {
      //removeCells变量必须是list格式不然无效
      this.graph.removeCells([this.contextmenu_cell]);
      this.context_menu = false;
      this.type = 'grid';
    },
    //批量删除按钮==========================================================================================================================
    delete_cell() {
      const cell = this.graph.getSelectedCells();
      this.graph.removeCells(cell);
      this.context_menu = false;
      this.type = 'grid';
    },
    //放大==========================================================================================================================
    zoom_in() {
      this.graph.zoom(0.2);
    },
    //缩小==========================================================================================================================
    zoom_out() {
      this.graph.zoom(-0.2);
    },
    //更改连线类型==========================================================================================================================
    change_edge_type(type) {
      //  console.log(type)
      if (type == 'normal') {
        this.line_type = 1;
        this.connect_edge_type = 'normal';
        this.router_edge_type = 'normal';
      } else {
        this.line_type = 2;
        this.connect_edge_type = 'rounded';
        this.router_edge_type = 'manhattan';
      }
    },
    //运行向后台传值==========================================================================================================================
    operation() {
      //需要将graph序列化获得node和edge的数据
      console.log(this.graph.toJSON());
      const cells = this.graph.toJSON();
      const node_list = [];
      const edge_list = [];
      let begin_node_id = '';
      //确定开始节点
      cells.cells.forEach((element) => {
        if (element.shape == 'edge') {
          edge_list.push(element);
        } else {
          node_list.push(element);
        }
      });
      if (edge_list.length > 0) {
        const target_list = [];
        edge_list.forEach((element) => {
          target_list.push(element.target.cell);
        });
        node_list.forEach((element) => {
          if (target_list.indexOf(element.id) < 0) {
            begin_node_id = element.id;
          }
        });
      }
      console.log(begin_node_id);
      this.$notify({
        title: '开始运行',
        type: 'success',
        message: h('i', { style: 'color: teal' }, '流程text1开始运行')
      });
    },
    //导出graph到png==========================================================================================================================
    download_png() {
      //等待下一次渲染执行结束后调用
      this.$nextTick(() => {
        this.graph.toPNG(
          (dataUri) => {
            // 下载
            DataUri.downloadDataUri(dataUri, '流程图.png');
          },
          {
            backgroundColor: 'white',
            padding: {
              top: 50,
              right: 50,
              bottom: 50,
              left: 50
            },
            quality: 1,
            copyStyles: false
          }
        );
      });
    },
    //画布重做==========================================================================================================================
    redo() {
      this.graph.redo();
    },
    //画布撤销==========================================================================================================================
    undo() {
      this.graph.undo();
    },
    //更改连线关系（lable）==========================================================================================================================
    change_edge_link() {
      //设置关系弹窗中input、selesct回显
      if (this.contextmenu_cell.toJSON().labels[0].text != '') {
        const rules = this.contextmenu_cell.toJSON().labels[0].attrs.text.text[0];
        const type = this.contextmenu_cell.toJSON().labels[0].attrs.text.type[0];
        this.custom_rules = [type == 'custom' ? rules : ''];
        this.return_rule = [type == 'default' ? rules : '自定义'];
      } else {
        this.custom_rule_inline = false;
        this.custom_rules = '';
        this.return_rule = '';
      }
      this.selection_rule = true;
    },
    //选择规则回调==========================================================================================================================
    rule_search(e) {
      this.custom_rule_inline = e === '自定义' ? true : false;
    },
    //设置关系弹窗确定按钮==========================================================================================================================
    selection_rules() {
      this.selection_rule = false;
      this.contextmenu_cell.setLabels({
        attrs: {
          text: {
            text: [this.return_rule == '自定义' ? this.custom_rules : this.return_rule],
            type: [this.return_rule == '自定义' ? 'custom' : 'default']
          }
        },
        position: {
          distance: 0.5 //0.5为默认中心值
        }
      });
    },
    //保存graph数据==========================================================================================================================
    save_graph_data() {
      this.$store.commit('graph_data/change_graph_data', this.graph.toJSON());
      this.$notify({
        title: '保存成功',
        type: 'success',
        message: h('i', { style: 'color: teal' }, '流程图text1保存成功')
      });
    },
    //帮助弹窗==========================================================================================================================
    graph_help_open() {
      this.graph_help = true;
    },
    //加载graph_data==========================================================================================================================
    get_graph_data() {
      // console.log('===============================================')
      // console.log(this.$route.query.type)
      // console.log('===============================================')
      if (this.$route.query.type != undefined) {
        this.$route.query.type == 'created' ? (this.data = JSON.parse(JSON.stringify(this.$store.state.graph_data.graph_data))) : '';
      }
    }
  }
};
</script>
<style scoped>
.graph {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;
}
.graph_page {
  display: flex;
  justify-content: center;
  position: relative;
}
.message_box {
  position: absolute;
  z-index: 1000;
  width: 20%;
  height: 100%;
  background-color: #ffffff;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 5px #d1d1d1;
  /* display: none; */
}
.graph_box {
  width: 80%;
  height: calc(100vh - 77px);
}
.context_menu {
  position: absolute;
  z-index: 2000;
  width: 150px;
  /* height: 200px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #d1d1d1;
}
.context_menu_btn {
  width: 94%;
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  border-bottom: 1px solid #d1d1d157;
  margin-left: 3%;
  margin-right: 3%;
}
.context_menu_btn > button {
  text-align: start;
  margin-left: 0px !important;
  line-height: 14px;
  font-size: 14px;
  color: #909399 !important;
}
.message_box_inline {
  margin: 10px;
}
.message_box_inline > p {
  width: 100%;
  text-align: start;
  margin: 10px;
}
.graph_ele_box {
  width: 19%;
  height: calc(100vh - 50px);
  box-shadow: 3px 3px 5px #d1d1d1;
  z-index: 100;
}
.graph_item_box {
  margin: 0px 10px 0px 10px;
}
.graph_element {
  display: flex;
  justify-content: flex-start;
  padding-top: 25px;
}
.graph_element_part {
  width: 82px;
  display: flex;
  flex-direction: column;
  margin: 0px 15px 0px 15px;
  justify-content: center;
  align-items: center;
}
.element_size {
  width: 50px;
}
.tool_bar {
  position: absolute;
  top: 15px;
  left: 21%;
  z-index: 10;
  background-color: #ffffff;
  /* width: 30%; */
  display: flex;
  border-radius: 5px;
  justify-content: center;
  box-shadow: 0px 0px 5px #d1d1d1;
}
.tool_bar > .button_group {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
}
.tool_bar > button {
  margin: 10px 10px 10px 0px;
}
.graph_edge_part {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 7px 15px;
  border-radius: 4px;
}
.graph_edge_part > p {
  text-align: center;
}
.line_type {
  background-color: #9093992e;
}
.tool_bar_icon {
  margin-right: 10px;
}
.operation {
  /* width: 90px; */
  margin-right: 16px;
  position: relative;
}
.operation::after {
  content: '';
  position: absolute;
  border-right: 1px solid #90939966;
  left: 131px;
  top: 5px;
  height: 30px;
}
.compile {
  margin-right: 20px;
  position: relative;
}
.compile::after {
  content: '';
  position: absolute;
  border-right: 1px solid #90939966;
  left: 187px;
  top: 5px;
  height: 30px;
}
.el-select {
  width: 100%;
}
.rules_box {
  margin-top: -30px;
}
.icon {
  font-size: 13px;
}
.title_box {
  margin: 8px 0px 3px 0px;
  display: flex;
  justify-content: space-between;
}
.title_icon_help {
  font-size: 22px;
}
.help_inline {
  display: flex;
  flex-direction: column;
}
.help_inline > span {
  margin-bottom: 5px;
}
</style>
