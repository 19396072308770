import axios from 'axios'
import router from '@/router/routers'
import {ElMessage, ElNotification} from 'element-plus'
import store from '../store'
import {getToken} from '@/utils/auth'
import Config from '@/settings'
import Cookies from 'js-cookie'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: Config.timeout
})

service.interceptors.request.use(
    config => {
        if (getToken()) {
          console.log("getToken()")
          console.log(getToken())
            config.headers['Authorization'] = getToken()
        }
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
      console.log(response)
        return response.data
    },
    error => {
        if (error.toString().indexOf('Error: Network Error') !== -1) {
            prompt('网络错误!')
            return Promise.reject(error)
        }
        if (
            error.response &&
            error.response.data &&
            error.response.data instanceof Blob &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
            const reader = new FileReader()

            reader.readAsText(error.response.data, 'utf-8')
            reader.onload = function () {
                const errorMsg = JSON.parse(reader.result).message

                prompt(errorMsg)
            }
        } else {
            let code = 0

            try {
                code = error.response.data.code
            } catch (e) {
                if (error.toString().indexOf('Error: timeout') !== -1) {
                    prompt('网络请求超时')
                    return Promise.reject(error)
                }
            }
            if (code) {
                if (code === 401 || code === 402) {
                    store.dispatch('LogOut').then(() => {
                        Cookies.set('point', 401 + 402)
                        location.reload()
                    })
                } else if (code === 403) {
                    router.push({path: '/401'}).then()
                } else if (code === 404) {
                    router.push({path: '/404'}).then()
                } else if (code === 400) {
                    const errorMsg = error.response.data.message
                    if (errorMsg !== undefined) {
                        ElMessage({
                            message: errorMsg,
                            type: 'error'
                        })
                    }
                    return Promise.resolve(error.response.data)
                }else if(code == 500){
                    const errorMsg = error.response.data.message
                    if (errorMsg !== undefined) {
                        ElMessage({
                            message: errorMsg,
                            type: 'error'
                        })
                    }
                } else {
                    prompt('未知接口状态码:' + code)
                }
            } else {
                prompt('接口请求失败,请查看具体详细信息!')
            }
        }
        return Promise.reject(error)
    }
)

function prompt(msg) {
    ElNotification({
        //title: 'Error',
        message: msg,
        type: 'error',
        duration: 6000
    })
}

export default service
