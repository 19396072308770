import {myAlarmCount} from '@/api/system/alert'

const state = {
        jobAlarm: {
            type: 'success',
            value: 0
        }
    },
    mutations = {
        SET_JOB_ALARM_DATA: (state, value) => {
            let [o, t] = value
            if (o !== 0) {
                state.jobAlarm.type = 'danger'
            } else {
                state.jobAlarm.type = 'success'
            }
            state.jobAlarm.value = o + t
        }
    },
    actions = {
        ChangeAlarmFlag: ({commit}) => {
            myAlarmCount()
                .then(res => {
                    const {code, data} = res
                    if (code === 200) commit('SET_JOB_ALARM_DATA', data)
                })
                .catch(() => {
                })
        }
    },
    getters = {
        alarmType: state => state.jobAlarm.type,
        alarmCount: state => state.jobAlarm.value
    }

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
