<template>
  <div class="queryModule">
    <div class="title" @click="toggleQuery">
      <p class="first_class_tittle">条件查找</p>
      <!-- <i class="el-icon-search " />条件查找 -->
    </div>
    <el-form :model="queryModule" v-show="queryModuleShow" :label-width="formLabelWidth" class="cont" ref="ref">
      <el-row>
        <slot />
      </el-row>
      <el-form-item class="queryBtn">
        <el-button type="primary" size="small" @click="reset"> 重置 </el-button>
        <el-button type="primary" size="small" @click="onSubmit"> 查询 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ['queryModule'],
  data() {
    return {
      formLabelWidth: '120px',
      queryModuleShow: false,
    }
  },
  mounted: function () {},
  methods: {
    toggleQuery() {
      this.queryModuleShow = !this.queryModuleShow
    },
    reset() {
      this.$emit('reset')
      this.$refs.ref.resetFields()
    },
    onSubmit() {
      var _this = this
      if (_this.queryModule.timeCond) {
        _this.queryModule.timeCond = _this.$publics.formatTime(_this.queryModule.timeCond)
      }
      _this.$emit('queryRes', _this.queryModule) //通用
    },
  },
}
</script>

<style scoped>
.queryModule {
  margin-bottom: 10px;
}

.queryModule .title {
  color: #333;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  padding: 5px;
  border: 0px;
  background-color: #f2f6fc;
}

.queryModule .cont {
  border: 1px solid #ddd;
  padding: 10px;
}

.queryModule .el-form-item.queryBtn {
  margin: 0;
  text-align: center;
}

.queryModule :deep(.el-select) {
  width: 100%;
}
</style>
