<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryCond" @queryRes="this.loadLogListData" @reset="reset">
        <el-col :span="8">
          <el-form-item label="时间" prop="createTime">
            <el-date-picker
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="queryCond.createTime"
              :shortcuts="this.$publics.shortcuts()"
              end-placeholder="结束时间"
              range-separator="-"
              start-placeholder="开始时间"
              type="datetimerange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="内容" prop="blurry">
            <el-input v-model="queryCond.blurry" placeholder="请输入你要搜索的内容!"></el-input>
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-table v-loading="listDataLoading" :data="listData.content" border header-align="center" highlight-current-row size="mini" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <el-descriptions :column="1" border>
              <el-descriptions-item label="请求方法">{{ props.row.method }}</el-descriptions-item>
              <el-descriptions-item label="请求参数"><highlightjs language="json" :code="props.row.params" /></el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="用户名" prop="username" />
        <el-table-column header-align="center" label="主机地址" prop="requestIp" />
        <el-table-column header-align="center" label="地址来源" prop="address" />
        <el-table-column header-align="center" label="描述" prop="description" />
        <el-table-column header-align="center" label="浏览器" prop="browser" />
        <el-table-column header-align="center" label="请求耗时" prop="time">
          <template #default="{ row }">
            <el-tag size="small">{{ row.time }}ms</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="创建日期" prop="createTime" />
      </el-table>
      <Pagination :current-page="queryCond.page + 1" :page-size="pageSize" :total="listData.totalElements" @changeCurrentPage="changePage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import Pagination from '/src/components/PaginationModule'

export default {
  name: 'operationLog',
  components: {
    BreadCrumb,
    QueryModule,
    Pagination,
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  data() {
    return {
      listDataLoading: true,
      listData: [],
      queryCond: { blurry: '', createTime: [], page: 0 },
    }
  },
  methods: {
    async loadLogListData() {
      const { code, data } = await this.$axios.POST('/api/v1/core/project/log/small', {
        ...this.queryCond,
        size: this.pageSize,
      })
      if (code == 200) {
        this.listDataLoading = false
        this.listData = data
      } else {
        console.error('获取操作日志失败!')
      }
    },
    changePage(currentPage) {
      this.queryCond.page = currentPage - 1
      this.loadLogListData()
    },
    reset() {
      this.queryCond.page = 0
      this.queryCond.blurry = ''
      this.queryCond.createTime = []
      this.loadLogListData()
    },
  },
  created() {
    this.loadLogListData()
  },
}
</script>

<style scoped></style>
