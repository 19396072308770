<template>
  <el-aside id="aside">
    <div class="logo" v-text="platformName"></div>
    <el-menu :default-active="defaultUrl" :default-openeds="[0]" :unique-opened="true" class="el-menu-vertical-demo" router>
      <el-sub-menu v-for="(item, i) in menus" v-show="isShow(item)" :key="i" :index="i">
        <template #title>
          <svg-icon :icon-class="item.icon" class-name="menuIcon" />
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item v-for="(item2, j) in item.child" v-show="isShow(item2)" :key="j" :index="item2.url"> {{ item2.name }} </el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-aside>
</template>

<script>
// import tree0 from '/src/assets/img/icons/tree_white/tree0.png'
// import tree1 from '/src/assets/img/icons/tree_white/tree1.png'
// import tree2 from '/src/assets/img/icons/tree_white/tree2.png'
// import tree4 from '/src/assets/img/icons/tree_white/tree4.png'
// import tree5 from '/src/assets/img/icons/tree_white/tree5.png'
// import tree6 from '/src/assets/img/icons/tree_white/tree6.png'
// import tree7 from '/src/assets/img/icons/tree_white/tree7.png'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavBar',
  components: {},
  computed: {
    ...mapGetters(['user']),
    ...mapState('settings', ['platformName']),
  },
  created() {
    this.defaultUrl = this.$route.path
    this.getDevMenu()
  },
  methods: {
    isShow(item) {
      if (item.isAdmin === undefined) return true
      if (item.isAdmin && this.user.isAdmin) return true
      if (!item.isAdmin && this.user.isAdmin) return false
      if (!item.isAdmin && !this.user.isAdmin) return true
      return false
    },
    getDevMenu() {
      this.menus = [
        {
          name: '工作概览',
          icon: 'overview',
          child: [
            {
              url: '/dashboard',
              name: '首页',
            },
          ],
        },
        {
          name: '系统管理',
          icon: 'system',
          isAdmin: true,
          child: [
            {
              url: '/system/user',
              name: '用户管理',
            },
            {
              url: '/system/operationLog/small',
              name: '操作日志',
            },
            {
              url: '/system/operationLog/error',
              name: '异常日志',
            },
          ],
        },
        {
          name: '采集管理',
          icon: 'collect',
          child: [
            {
              url: '/collect/data_source',
              name: '数据源',
            },
          ],
        },
        {
          name: '项目管理',
          icon: 'project',
          child: [
            {
              url: '/projects/project',
              name: '我的项目',
            },
          ],
        },

        {
          name: '规则管理',
          icon: 'rule',
          child: [
            {
              url: '/rules/classification',
              name: '预定义规则管理',
            },
            {
              url: '/rules/module',
              name: '模板管理',
            },
          ],
        },
        {
          name: '报告管理',
          icon: 'report',
          child: [
            {
              url: '/report/reportExport',
              name: '质量报告',
            },
            // {
            //   url: '/quality/report/evaluating',
            //   name: '评估报告',
            // },
            // {
            //   url: '/quality/report/quality',
            //   name: '质量报告',
            // },
          ],
        },
        {
          name: '任务监控',
          icon: 'monitor',
          child: [
            {
              url: '/task/taskMonitor',
              name: '任务报表',
            },
          ],
        },
        {
          name: '质量问题',
          icon: 'problem',
          child: [
            {
              url: '/quality/problem/list/index',
              name: '问题清单',
            },
            {
              url: '/quality/problem/summary/index',
              name: '问题总结',
            },
          ],
        },
        {
          name: '工单管理',
          icon: 'workOrder',
          child: [
            {
              url: '/issueTicket/list',
              name: '工单列表',
              isAdmin: true,
            },
            {
              url: '/myIssueTicket',
              name: '我的工单',
              isAdmin: false,
            },
          ],
        },
        {
          name: '数据服务',
          icon: 'dataService',
          child: [
            {
              url: '/infoQueryService',
              name: '查询服务',
            },
            {
              url: '/dataServiceList',
              name: '服务清单',
            },
          ],
        },
        {
          name: '知识库',
          icon: 'knowledgeBase',
          child: [
            // {
            //   url: '/knowledge/list',
            //   name: '知识分类',
            // },
            {
              url: '/knowledge/category',
              name: '知识清单',
            },
          ],
        },
        {
          name: 'ETL工具',
          icon: 'knowledgeBase',
          child: [
            {
              url: '/governance/process/graph/index',
              name: '治理规则管理',
            },
          ],
        },
        {
          name: '数据质量',
          icon: 'project',
          child: [
            {
              url: '/machineLearning/learning',
              name: '机器学习',
            },
          ],
        },
      ]
    },
  },
  data() {
    return {
      defaultUrl: '',
      menus: [],
    }
  },
}
</script>
<style scoped>
#aside {
  width: 260px;
  background-color: #fff;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#aside :deep(.el-menu) {
  height: 100%;
  overflow: hidden;
  width: 260px;
  background-color: #001529;
  border: 0;
}

#aside :deep(.el-sub-menu__title) {
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 30px !important;
}

#aside :deep(.el-menu-item:focus),
#aside :deep(.el-menu-item:hover),
#aside :deep(.el-sub-menu__title:focus),
#aside :deep(.el-sub-menu__title:hover) {
  background-color: #464e5a;
  outline: 0;
}

#aside :deep(.el-menu-item.is-active) {
  color: #fbfcfd;
  background-color: #00b5ad;
}

#aside :deep(.el-sub-menu__title i) {
  color: #fff;
}

#aside :deep(.el-sub-menu__title img) {
  width: 22px;
  margin-right: 8px;
}

#aside :deep(.el-menu-item),
#aside :deep(.el-sub-menu__title) {
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  color: rgb(191, 203, 217);
}

#aside :deep(.el-menu--inline .el-menu-item) {
  padding-left: 70px !important;
}

#aside .logo {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background-color: #001529;
  color: #fff;
  font-size: 20px;
}
</style>
