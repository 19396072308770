<template>
  <div class="page">
    <div style="display: none">
      <div>已完成的</div>
      <div>可以从4个方面生成数据质量问题 1.告警（手动、自动）2.手动添加 3.评估报告</div>
      <div>可以阅览告警规则 并可以修改规则</div>
      <div>未完成的</div>
      <div>对问题数据和规则明细数据支持预览 和 组合查询 ok</div>
      <div>问题处理 提供数据上报接口 上报到第三方管理平台 no</div>
      <div>数据质量问题可以生成工单，生成的工单在工单管理中展示 no</div>
      <div>问题总结：1.问题如果发起工单流程后，订单流程走完可对数据质量问题进行总结 2.未发起工单流程可直接对问题进行总结。总结完成后数据写入问题总结列表 一个问题只能总结一次</div>
    </div>
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryCond" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-form-item :label-width="120" label="告警时间">
            <el-date-picker v-model="queryCond.timeCond" :shortcuts="this.$publics.shortcuts()" end-placeholder="结束时间" range-separator="-" start-placeholder="开始时间" type="datetimerange" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="问题来源" prop="alarmLevel">
            <el-select v-model="queryCond.issueSource" placeholder="请选择问题来源">
              <el-option label="告警规则" value="0" />
              <el-option label="手工生成" value="1" />
              <el-option label="评估报告" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
      </QueryModule>

      <el-row :gutter="20">
        <el-col :span="5">
          <div class="tree_title">
            <p class="first_class_tittle">项目作业导航</p>
          </div>
          <div class="block tree_box">
            <el-input v-model="filterText" placeholder="关键字" />
            <el-tree
              ref="treeRef"
              v-loading="treeLoading"
              :accordion="true"
              :data="treeData"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :highlight-current="true"
              class="filter-tree"
              node-key="id"
              @node-click="nodeClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <span>{{ node.label }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="table_title">
            <p class="first_class_tittle">问题列表</p>
          </div>
          <div class="table_box">
            <el-table v-loading="listLoading" :data="listData.content" border header-align="center" highlight-current-row size="mini" stripe>
              <el-table-column :index="queryCond.page * this.pageSize + 1" fixed header-align="center" label="序号" type="index" width="50" />
              <el-table-column header-align="center" label="问题原因" prop="issueDesc" show-overflow-tooltip="true" width="200" />
              <el-table-column header-align="center" label="所属作业" prop="workName" show-overflow-tooltip="true" width="200" />
              <el-table-column header-align="center" label="任务编号" prop="taskId" width="50" />
              <el-table-column header-align="center" label="问题来源" prop="issueSource" width="80">
                <template #default="{ row }">
                  <span v-if="row.issueSource === 0">告警规则</span>
                  <span v-else-if="row.issueSource === 1">手工生成</span>
                  <span v-else-if="row.issueSource === 2">评估报告</span>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="问题状态" prop="issueStatus" width="80">
                <template #default="{ row }">
                  <span v-if="row.issueStatus == 0">未处理</span>
                  <span v-else-if="row.issueStatus == 1">处理中</span>
                  <span v-else-if="row.issueStatus == 2">已处理</span>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="创建人" prop="username" width="80" />
              <el-table-column header-align="center" label="生成时间" prop="createTime" width="150" />
              <el-table-column fixed="right" header-align="center" label="操作" width="250">
                <template v-slot:default="{ row, $index }">
                  <el-button circle size="mini" title="查看告警规则" type="primary" @click="onClickAlarmRule(row.taskId)">
                    <el-icon>
                      <i-bell-filled />
                    </el-icon>
                  </el-button>
                  <el-button circle size="mini" title="问题总结" type="primary" @click="onCreateProblemSummary(row)">
                    <el-icon>
                      <i-user-filled />
                    </el-icon>
                  </el-button>

                  <el-button :disabled="row.issueStatus != 0" circle size="mini" title="生成工单" type="primary" @click="onCreateIssueTicket(row, $index)">
                    <el-icon>
                      <i-plus />
                    </el-icon>
                  </el-button>
                  <el-popconfirm confirm-button-text="确定" cancel-button-text="取消"  icon-color="red" title="确认解决此问题么?" @confirm="onsChangeIssueStatus(row, $index)">
                    <template #reference>
                      <el-button size="mini" circle type="primary" :disabled="!(row.issueStatus == 1)">
                        <el-icon><i-select /> </el-icon>
                      </el-button>
                    </template>
                  </el-popconfirm>

                  <el-button circle disabled size="mini" title="预览数据" type="primary">
                    <el-icon>
                      <i-view />
                    </el-icon>
                  </el-button>

                  <el-button circle disabled size="mini" title="数据上报" type="primary">
                    <el-icon>
                      <i-upload-filled />
                    </el-icon>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagination :current-page="queryCond.page + 1" :page-size="pageSize" :total="listData.totalElements" @changeCurrentPage="changePage" />
        </el-col>
      </el-row>
    </div>
  </div>
  <TaskAlarmRuleDialog ref="taskAlarmRule" :isOperation="false" />
  <RichTextDialog
    ref="richTextDialogRef"
    v-model:display="summaryDisplay"
    :preview="false"
    :richTextModel="richTextModel"
    :richTextModelRules="richTextModelRules"
    title="问题总结"
    @saveContent="saveProblemSummary"
  >
    <el-col :span="12">
      <el-form-item label="标题" prop="problemDescription">
        <el-input v-model="richTextModel.problemDescription"></el-input>
      </el-form-item>
    </el-col>
  </RichTextDialog>

  <el-dialog v-model="ticket.isShow" title="添加工单" @close="$refs.issueTicketRef.resetFields()">
    <el-form ref="issueTicketRef" :model="ticket.ticketFrom" :rules="ticket.ticketFromRules" :status-icon="true" label-width="100px">
      <el-form-item label="工单标题" prop="issueTicketTitle">
        <el-input v-model="ticket.ticketFrom.issueTicketTitle" placeholder="请输入工单标题" />
      </el-form-item>
      <el-form-item label="工单描述" prop="issueTicketContent">
        <el-input v-model="ticket.ticketFrom.issueTicketContent" placeholder="请输入工单描述内容" type="textarea" />
      </el-form-item>
      <el-form-item label="工单级别" prop="issueTicketPriority">
        <el-radio-group v-model="ticket.ticketFrom.issueTicketPriority">
          <el-radio :label="0">低</el-radio>
          <el-radio :label="1">中</el-radio>
          <el-radio :label="2">高</el-radio>
          <el-radio :label="3">紧急</el-radio>
          <el-radio :label="4">非常紧急</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="mini" style="text-align: center">
        <el-button type="primary" @click="onSaveIssueTicket">保存</el-button>
        <el-button @click="ticket.isShow = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import Pagination from '/src/components/PaginationModule'
import TaskAlarmRuleDialog from '../../../../../components/TaskAlarmRuleDialog'
import RichTextDialog from '../../../../../../components/RichTextDialog'
import { addIssueSummaryApi } from '@/api/project/issue/issueSummary'
import { addIssueTicketApi } from '@/api/project/issue/issueTicket'
import { updateProblemStatus } from '@/api/project/issue/qualityIssue'
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue'
import { mapGetters, mapMutations, useStore } from 'vuex'

export default {
  components: { BreadCrumb, QueryModule, Pagination, TaskAlarmRuleDialog, RichTextDialog },
  setup(title) {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const listLoading = computed(() => store.getters['quality/problem/problemListIndex/listLoading'])
    const treeLoading = computed(() => store.getters['quality/problem/problemListIndex/treeLoading'])
    const treeData = computed(() => store.getters['quality/problem/problemListIndex/treeData'])
    const listData = computed(() => store.getters['quality/problem/problemListIndex/listData'])
    const getTreeData = () => store.dispatch('quality/problem/problemListIndex/getTreeData')
    const getListData = d => store.dispatch('quality/problem/problemListIndex/getListData', d)
    provide('richTextEditorConfig', { height: 450, dir: 'problemSummary' })
    let filterText = ref('')
    const query = { treeId: '', issueSource: '', timeCond: [], page: 0, size: store.getters.pageSize }
    let queryCond = ref({ ...query })
    const queryRes = () => getListData({ ...queryCond.value })
    const reset = () => {
      queryCond.value = { ...query }
      getListData(queryCond.value)
    }
    const changePage = page => getListData(auto(queryCond.value.treeId, page - 1))
    const auto = (treeId, page) => {
      queryCond.value = { ...queryCond.value, treeId, page }
      return queryCond.value
    }
    const nodeClick = node => {
      let ids = []
      if (node.mark === 'project') {
        ids = node.children
          .map(item => item.id)
          .toString()
          .split(',')
      } else if (node.mark === 'work') {
        ids = node.id
      }
      if (ids) getListData(auto(ids, queryCond.value.page))
    }

    watch(filterText, val => proxy.$refs.treeRef.filter(val))
    let filterNode = (value, data) => {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    }
    onMounted(() => {
      getTreeData()
      getListData(queryCond.value)
    })
    return {
      title,
      filterText,
      filterNode,
      listLoading,
      treeLoading,
      queryCond,
      listData,
      treeData,
      nodeClick,
      queryRes,
      reset,
      changePage,
    }
  },
  data() {
    return {
      summaryDisplay: false,
      richTextModel: {
        problemDescription: '',
      },
      richTextModelRules: {
        problemDescription: [
          {
            required: true,
            message: '请输入问题问题描述!',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 15,
            message: '请输入问题描述3-15个字符!',
            trigger: 'blur',
          },
        ],
      },
      ticket: {
        isShow: false,
        ticketFrom: {
          issueTicketTitle: '',
          issueTicketContent: '',
          issueTicketPriority: 0,
          issueId: '',
        },
        ticketFromRules: {
          issueTicketTitle: [
            {
              required: true,
              message: '工单标题不能为空!',
              trigger: 'blur',
            },
            {
              min: 3,
              max: 10,
              message: '工单标题只能填写3-10个字符!',
              trigger: 'blur',
            },
          ],
          issueTicketContent: [
            {
              required: true,
              message: '工单内容不能为空!',
              trigger: 'blur',
            },
            {
              min: 3,
              max: 20,
              message: '工单内容只能填写3-20个字符!',
              trigger: 'blur',
            },
          ],
        },
      },
      currentOperationRow: {
        row: null,
        index: 0,
      },
    }
  },
  methods: {
    ...mapMutations({
      updateIssueStatus: 'quality/problem/problemListIndex/UPDATE_ISSUE_STATUS_LIST_DATA_BY_INDEX',
    }),
    onClickAlarmRule(taskId) {
      this.$refs.taskAlarmRule.dialogIsShow = true
      this.$refs.taskAlarmRule.loadPageDataByTreeId(taskId)
    },
    onCreateProblemSummary(row) {
      this.currentOperationRow.row = row
      this.summaryDisplay = true
      this.$refs.richTextDialogRef.echo({ text: '', html: '' })
    },
    async saveProblemSummary(richTextContent) {
      const { code, message } = await addIssueSummaryApi({
        title: this.richTextModel.problemDescription,
        content: richTextContent.text,
        contentHtml: richTextContent.html,
        issueId: this.currentOperationRow.row.issueId,
      })
      if (code === 200) {
        this.$message.success('问题总结成功!')
        this.summaryDisplay = false
      } else {
        this.$message.warning(message)
      }
    },
    onCreateIssueTicket(row, index) {
      this.ticket.isShow = true
      this.ticket.ticketFrom.issueId = row.issueId
      this.currentOperationRow = { row, index }
    },
    onSaveIssueTicket() {
      this.$refs.issueTicketRef.validate(async valid => {
        if (valid) {
          const { code, message } = await addIssueTicketApi({ ...this.ticket.ticketFrom })
          if (code == 200) {
            this.ticket.isShow = false
            this.$message.success('工单添加成功!')
            this.updateIssueStatus({ index: this.currentOperationRow.index, status: 1 })
          } else if (code == 400) {
            this.$message.warning(message)
          } else {
            this.$message.error('工单添加失败,请联系开发人员!')
          }
        } else {
          return false
        }
      })
    },
    async onsChangeIssueStatus(data, index) {
      const status = 2
      const { code, message } = await updateProblemStatus({
        issueId: data.issueId,
        status,
      })
      if (code == 200) {
        this.$message.success('问题解决成功!')
        this.updateIssueStatus({ index, status })
      } else if (code == 400) {
        this.$message.warning(message)
      } else {
        this.$message.error('问题状态更新失败,请联系开发人员!')
      }
    },
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
}
</script>

<style scoped>
.tag-group span {
  margin: 0 1px;
}
</style>
