import request from '@/utils/request'

export function login(username, password, text, uuid) {
  return request({
    url: 'api/v1/public/auth/login',
    method: 'post',
    data: {
      username,
      password,
      text,
      uuid,
    },
  })
}

export function getInfo() {
  return request({
    url: 'api/v1/public/auth/info',
    method: 'get',
  })
}

export function getCodeImg() {
  return request({
    url: 'api/v1/public/auth/code',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: 'api/v1/public/auth/logout',
    method: 'delete',
  })
}
