import { problemListIndexModuleStore } from './list/index/quality.problem-list-index.store'
import { problemSummaryIndexModuleStore } from './summary/index/problem-summary-index.store'

export const problemStoreModule = {
  namespaced: true,
  modules: {
    problemListIndex: problemListIndexModuleStore,
    problemSummaryIndex: problemSummaryIndexModuleStore,
  },
}
