import { GET_SER, PUT,GET } from '@/utils/api'

export function qualityIssueList(data) {
  return GET_SER('/api/v1/core/project/qualityIssue/list', data)
}
export function addQualityIssue(data) {
  return PUT('/api/v1/core/project/qualityIssue', data)
}
export function updateProblemStatus(data) {
  return GET('/api/v1/core/project/qualityIssue/updateProblemStatus', data)
}
