<template>
  <ElConfigProvider :locale="locale">
    <router-view />
  </ElConfigProvider>
</template>
<script>
import { ElConfigProvider } from 'element-plus'
import { ref } from 'vue'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'

export default {
  name: 'APP',
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    const locale = ref(zhLocale)

    return {
      locale,
    }
  },
}
</script>
