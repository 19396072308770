<template>
  <el-dialog v-model="dialogIsShow" title="任务告警规则" width="70%">
    <el-scrollbar height="400px">
      <QueryModule :query-module="queryCond" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-form-item :label-width="120" label="告警时间">
            <el-date-picker v-model="queryCond.alarmTime" :shortcuts="this.$publics.shortcuts()" end-placeholder="结束时间" range-separator="-" start-placeholder="开始时间" type="datetimerange" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="告警级别" prop="alarmLevel">
            <el-select v-model="queryCond.alarmLevel" placeholder="请选择告警级别">
              <el-option v-for="(item, index) of selectedData.AlarmLevelEnum" :key="index" :label="item.LABEL" :value="item.CODE" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="120" label="校验对象">
            <el-select v-model="queryCond.checkObject" placeholder="请选择校验对象">
              <el-option v-for="(item, index) of selectedData.CheckObjectEnum" :key="index" :label="item.LABEL" :value="item.CODE" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="120" label="校验模板" prop="checkTemplate">
            <el-select v-model="queryCond.checkTemplate" placeholder="请选择告警级别">
              <el-option v-for="(item, index) of selectedData.CheckTemplateEnum" :key="index" :label="item.LABEL" :value="item.CODE" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="120" label="报警状态">
            <el-select v-model="queryCond.isIgnore" placeholder="请选择">
              <el-option key="0" label="未忽略" value="0" />
              <el-option key="1" label="已忽略" value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="120" label="质量问题">
            <el-select v-model="queryCond.isGenerateProblem" placeholder="请选择">
              <el-option key="0" label="未生成" value="0" />
              <el-option key="1" label="已生成" value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="120" label="校验结果">
            <el-select v-model="queryCond.isPass" placeholder="请选择">
              <el-option key="0" label="未通过" value="0" />
              <el-option key="1" label="通过" value="1" />
            </el-select>
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-table v-loading="listLoading" :data="listData.content" border header-align="center" highlight-current-row size="mini" stripe>
        <el-table-column header-align="center" label="序号" type="index" width="50" />
        <el-table-column header-align="center" label="告警时间" prop="alarmTime" width="140" />
        <el-table-column header-align="center" label="告警类型" prop="alarmLevel" width="80">
          <template #default="scope">
            <el-tag v-for="item in selectedData.AlarmLevelEnum" v-show="scope.row.alarmLevel === item.CODE" :key="item.CODE" :type="item.TYPE" effect="dark" size="mini">
              {{ item.LABEL }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="校验结果" prop="isPass" width="80">
          <template #default="scope">
            <el-tag v-if="scope.row.isPass" effect="plain" size="mini" type="success"> 通过 </el-tag>
            <el-tag v-else effect="plain" size="mini" type="danger"> 未通过 </el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="校验对象" prop="checkObject" width="80">
          <template #default="scope">
            <span v-for="item in selectedData.CheckObjectEnum" v-show="scope.row.checkObject === item.CODE" :key="item.CODE" v-text="item.LABEL" />
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="校验模板" prop="checkTemplate" width="80">
          <template #default="scope">
            <span v-for="item in selectedData.CheckTemplateEnum" v-show="scope.row.checkTemplate === item.CODE" :key="item.CODE" v-text="item.LABEL" />
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="任务运行结果" prop="compareType" width="100">
          <template #default="scope">
            <span v-text="scope.row.result" />
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="报警状态" prop="isIgnore" width="80">
          <template #default="scope">
            <span v-text="scope.row.isIgnore ? '已忽略' : '未忽略'" />
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="问题状态" prop="isGenerateProblem" width="80">
          <template #default="scope">
            <span v-text="scope.row.isGenerateProblem ? '已生成' : '未生成'" />
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="操作">
          <template #default="scope">
            <el-button circle size="mini" title="告警原因" type="" @click="alarmReason(scope.row)">
              <el-icon>
                <i-chat-round />
              </el-icon>
            </el-button>

            <el-popconfirm
              v-if="isOperation"
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="red"
              size="mini"
              title="确定要忽略告警么,忽略后将不在提示?"
              @confirm="ignoreWarning(scope.row)"
            >
              <template #reference>
                <el-button :disabled="scope.row.isIgnore ? true : false" circle size="mini" title="忽略告警" type="success">
                  <el-icon>
                    <i-circle-check />
                  </el-icon>
                </el-button>
              </template>
            </el-popconfirm>

            <el-popconfirm v-if="isOperation" disabled cancel-button-text="取消" confirm-button-text="确定" icon-color="red" size="mini" title="确定要删除警告么?" @confirm="deleteWarning(scope.row)">
              <template #reference>
                <el-button circle size="mini" title="删除" type="danger">
                  <el-icon>
                    <i-delete />
                  </el-icon>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :current-page="queryCond.page + 1" :page-size="pageSize" :total="listData.totalElements" @changeCurrentPage="changeCurrentPage" />
    </el-scrollbar>
  </el-dialog>

  <TaskAlarmReasonDialog ref="taskAlarmReason" />
</template>

<script>
import QueryModule from '/src/components/QueryModule'
import Pagination from '/src/components/PaginationModule'
import { alarmFormSelected } from '@/api/project/work'
import { deleteWarning, ignoreWarning, myAlarmList } from '@/api/system/alert'
import TaskAlarmReasonDialog from '../TaskAlarmReasonDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskAlarmRuleDialog',
  components: {
    QueryModule,
    Pagination,
    TaskAlarmReasonDialog,
  },
  props: ['isOperation'],
  data() {
    return {
      selectedData: {},
      listData: {},
      dialogAlarmList: {
        isShow: false,
      },
      listLoading: true,
      queryCond: {
        treeId: '',
        alarmLevel: '',
        checkObject: '',
        checkTemplate: '',
        alarmTime: '',
        isGenerateProblem: '',
        isIgnore: '',
        isPass: '',
        page: 0,
      },
      dialogIsShow: false,
      nodeKey: null,
    }
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  methods: {
    ...mapActions('alarm', ['ChangeAlarmFlag']),
    queryRes() {
      this.loadPageData(this.listCallback)
    },
    reset() {
      const treeId = this.$data.queryCond.treeId
      Object.assign(this.$data.queryCond, this.$options.data().queryCond)
      this.$data.queryCond = { ...this.$data.queryCond, treeId }
      this.loadPageData(this.listCallback)
    },
    changeCurrentPage(currentPage) {
      this.queryCond.page = currentPage - 1
      this.loadPageData(this.listCallback)
    },
    async loadPageData(callback) {
      this.listLoading = true
      const { data, code } = await myAlarmList({
        ...this.queryCond,
        size: this.pageSize,
        alarmTime: this.$publics.formatTime(this.queryCond.alarmTime),
      })
      if (code === 200) callback(data)
    },
    listCallback(data) {
      this.listData = data
      this.listLoading = false
    },
    async loadSelectedData() {
      const { data, code } = await alarmFormSelected()
      if (code === 200) this.selectedData = data
    },
    async ignoreWarning(row) {
      const { data, code } = await ignoreWarning(row.id)
      if (code === 200 && data) await this.successCallback('忽略成功', row.id)
    },
    async deleteWarning(row) {
      const { data, code } = await deleteWarning(row.id)
      if (code === 200 && data) await this.successCallback('删除成功', row.id)
    },
    async successCallback(message, alarmId) {
      await this.loadPageData(this.listCallback)
      this.$emit('treeDataUpdate', this.nodeKey, alarmId)
      await this.ChangeAlarmFlag()
      this.$message({
        type: 'success',
        message,
      })
    },
    alarmReason(row) {
      this.$refs.taskAlarmReason.dialogAlarmReason = { isShow: true, row }
    },
    loadPageDataByTreeId(taskId, nodeKey) {
      this.queryCond.treeId = taskId
      this.nodeKey = nodeKey
      this.loadPageData(this.listCallback)
    },
  },
  mounted() {
    this.loadSelectedData()
  },
}
</script>

<style scoped></style>
