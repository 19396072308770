import { createStore } from 'vuex'
import { qualityStoreModule } from '../views/home/quality/quality.store'
import getters from './getters'

const modulesFiles = require.context('./modules', true, /\.js$/),
  modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1'),
      value = modulesFiles(modulePath)

    modules[moduleName] = value.default
    return modules
  }, {})

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: { ...modules, quality: qualityStoreModule },
})
