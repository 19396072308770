import request from '@/utils/request'
import qs from 'qs'

export const GET = (url, data) => {
    return request({
        url,
        method: 'GET',
        params:data
    })
}
export const POST = (url, data) => {
    return request({
        url,
        method: 'POST',
        data
    })
}
export const PUT = (url, data) => {
    return request({
        url,
        method: 'PUT',
        data
    })
}

export const DELETE = url => {
    return request({
        url,
        method: 'DELETE'
    })
}

export const GET_SER = (url, params) => {
    return request({
        url: url + '?' + qs.stringify(params, {indices: false}),
        method: 'get'
    })
}

export const DOWNLOAD = (url, params) => {
    return request({
        url: url + '?' + qs.stringify(params, {indices: false}),
        method: 'GET',
        responseType: 'blob'
    })
}
export const REQUEST = () => {
    return request()
}

export default {
    GET,
    POST,
    PUT,
    DELETE,
    GET_SER,
    DOWNLOAD,
    REQUEST
}
