<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryCond" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-form-item label="标题" prop="title">
            <el-input v-model="queryCond.title" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label-width="120" label="创建时间" prop="timeCond">
            <el-date-picker
              v-model="queryCond.timeCond"
              :editable="false"
              :shortcuts="this.$publics.shortcuts()"
              end-placeholder="结束时间"
              range-separator="-"
              start-placeholder="开始时间"
              type="datetimerange"
            />
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-table v-loading="listLoading" :data="listData.content">
        <el-table-column :index="(queryCond.page - 1) * pageSize + 1" fixed header-align="center" label="序号" type="index" width="50" />
        <el-table-column header-align="center" label="标题" prop="title" show-overflow-tooltip="true" />
        <el-table-column header-align="center" label="所属问题" prop="qualityIssue.issueDesc" show-overflow-tooltip="true" />
        <el-table-column header-align="center" label="所属作业" prop="qualityIssue.workName" show-overflow-tooltip="true" />
        <el-table-column header-align="center" label="创建人" prop="createBy" show-overflow-tooltip="true" />
        <el-table-column header-align="center" label="更新人" prop="update" />
        <el-table-column header-align="center" label="创建时间" prop="createTime" />
        <el-table-column header-align="center" label="最后一次更新时间" prop="updateTime" />
        <el-table-column header-align="center" label="是否归档知识库" prop="issueDesc" show-overflow-tooltip="true">
          <template #default="{ row }">
            {{ !row.isArchive ? '未归档' : '已归档' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip class="item" content="预览" effect="light" placement="top-end">
              <el-button circle size="mini" type="primary" @click="onPreviewProblemSummary(scope.row)">
                <el-icon>
                  <i-view />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-popconfirm cancel-button-text="取消" confirm-button-text="确定" icon-color="red" size="mini" title="确定要删除总结?" @confirm="delProblemSummaryConfirm(scope.row.id)">
              <template #reference>
                <el-button circle size="mini" title="删除" type="danger">
                  <el-icon>
                    <i-delete-filled />
                  </el-icon>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :current-page="queryCond.page" :page-size="pageSize" :total="listData.totalElements" @changeCurrentPage="changePage" />
    </div>
  </div>
  <RichTextDialog ref="richTextDialogRef" v-model:display="richText.display" :richTextModel="richText.richTextModel" :richTextModelRules="richText.richTextModelRules" title="问题总结">
    <el-col :span="12">
      <el-form-item label="标题" prop="problemDescription">
        <el-input v-model="richText.title"></el-input>
      </el-form-item>
    </el-col>
  </RichTextDialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import Pagination from '/src/components/PaginationModule'
import QueryModule from '/src/components/QueryModule'
import RichTextDialog from '../../../../../../components/RichTextDialog'
import { computed, getCurrentInstance, nextTick, onMounted, reactive, watch } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  name: 'problem-summary-list',
  components: { BreadCrumb, Pagination, QueryModule, RichTextDialog },
  setup() {
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const listLoading = computed(() => store.getters['quality/problem/problemSummaryIndex/listLoading'])
    const listData = computed(() => store.getters['quality/problem/problemSummaryIndex/listData'])
    const queryCond = reactive({ timeCond: [], page: 1, size: store.getters.pageSize, title: '' })
    const richText = reactive({ display: false, richTextModel: null, richTextModelRules: {}, title: '' })
    const getListData = d => store.dispatch('quality/problem/problemSummaryIndex/getListData', d)
    const deleteIssueSummary = d => store.dispatch('quality/problem/problemSummaryIndex/deleteIssueSummary', d)
    const queryRes = () => getListData({ ...queryCond })
    watch(
      () => queryCond.page,
      () => getListData(queryCond),
    )
    const changePage = page => (queryCond.page = page)
    const reset = () => {
      nextTick(() => getListData(queryCond))
    }
    const delProblemSummaryConfirm = id => delProblemSummary(id)
    const delProblemSummary = id => {
      deleteIssueSummary(id).then(({ code, message }) => {
        if (code == 200) {
          ElMessage.success('删除成功!')
          getListData(queryCond)
          return
        }
        ElMessage.warning(message)
      })
    }
    const onPreviewProblemSummary = row => {
      richText.display = true
      richText.title = row.title
      proxy.$refs.richTextDialogRef.echo({ html: row.contentHtml })
    }
    onMounted(() => {
      getListData(queryCond)
    })
    return {
      listLoading,
      listData,
      queryCond,
      queryRes,
      changePage,
      reset,
      delProblemSummary,
      delProblemSummaryConfirm,
      richText,
      onPreviewProblemSummary,
    }
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
}
</script>

<style scoped></style>
