import router from '@/router/routers'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Config from '@/settings'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  to.meta.title ? (document.title = Config.title + '-' + to.meta.title) : (document.title = Config.title)
  NProgress.start()
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else if (store.getters.user) {
      store
        .dispatch('GetInfo')
        .then()
        .catch(() => {
          store.dispatch('LogOut').then(() => {
            location.reload()
          })
        })
      next()
    } else {
      next()
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    next(`/login?redirect=${to.fullPath}`)
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})
