<template>
  <el-dialog fullscreen :model-value="display" :title="title" @close="this.$emit('update:display', false)" @closed="closed" width="80%">
    <template #title> </template>
    <template #default>
      <el-form label-position="left" label-width="120px" :model="richTextModel" ref="richTextModelRef" :rules="richTextModelRules">
        <el-row :gutter="20">
          <slot></slot>
        </el-row>
        <RichTextEditor ref="root" @updateContent="updateContent" />
        <el-form-item style="text-align: center" v-if="!preview">
          <el-button size="mini" @click="reset">重置</el-button>
          <el-button size="mini" type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, nextTick, provide } from 'vue'
import RichTextEditor from '@/components/RichTextEditor'
import { ElMessage } from 'element-plus'
export default {
  name: 'rich-text-dialog',
  components: {
    RichTextEditor,
  },
  props: {
    preview: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '文档知识库',
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
    titleLabel: {
      type: String,
      default: '标题',
      required: true,
    },
    richTextModel: {
      type: Object,
      required: true,
    },
    richTextModelRules: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    let content = reactive({
      text: '',
      html: '',
    })
    provide('richTextEditorMenuConfig', { show: !props.preview })
    const save = () => {
      if (!content.text || !content.html) ElMessage.error('文本内容不能为空!')
      proxy.$refs.richTextModelRef.validate(flag => {
        if (flag) emit('saveContent', content)
      })
    }
    const echo = c => {
      nextTick(() => {
        proxy.$refs.root.initContent(c)
      })
    }
    const reset = () => closed()

    const updateContent = ({ text, html }) => {
      content.text = text
      content.html = html
    }
    const closed = () => {
      proxy.$refs.richTextModelRef.resetFields()
      proxy.$refs.root.clearContent()
    }
    onMounted(() => {})
    return {
      closed,
      save,
      reset,
      content,
      updateContent,
      echo,
    }
  },
}
</script>

<style scoped></style>
