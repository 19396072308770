<template>
  <el-breadcrumb class="page-header">
    <el-breadcrumb-item
      v-for="(v, i) in list"
      :key="i"
    >
      {{ v }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  watch: {
    $route(to) {
      let matched = to.matched

      this.list = matched
    }
  },
  created: function () {
    this.list = this.$route.matched[1].meta.nested
  },
  methods: {}
}
</script>
<style></style>
