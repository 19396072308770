import DataSourceLinkIndex from './datasource/link/datasource-link-index'
import ProcessGraphIndex from './process/graph/process-graph-index'

const routes = [
    {
        path: '/governance/datasource/link/index',
        name: 'linkIndex',
        meta: { title: '策略配置', nested: ['', ''] },
        component: DataSourceLinkIndex,
    },
    {
        path: '/governance/process/graph/index',
        name: 'graphIndex',
        meta: { title: '关系配置', nested: ['', ''] },
        component: ProcessGraphIndex,
    },
]

export default routes
