<template>
    <el-container>
      <NavBar />
      <el-main>
        <el-header>
          
          <div>
            <el-dropdown>
              <span class="el-dropdown-link">
                <el-badge
                  :type="alarmType"
                  is-dot
                >
                  <span
                    is-dot
                    v-text="user.nickname"
                  />
                  <el-icon :size="15"> <i-arrow-down /> </el-icon></el-badge>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <router-link to="/user/alert">
                    <el-dropdown-item>
                      <el-badge
                        :max="999"
                        :type="alarmType"
                        :value="alarmCount"
                        class="item"
                      >
                        我的告警
                      </el-badge>
                    </el-dropdown-item>
                  </router-link>
                  <router-link to="/user/center">
                    <el-dropdown-item divided>
                      <el-badge>个人中心</el-badge>
                    </el-dropdown-item>
                  </router-link>
                  <span
                    style="display: block"
                    @click="open"
                  >
                    <el-dropdown-item divided> 退出登录 </el-dropdown-item>
                  </span>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>
        <router-view />
      </el-main>
    </el-container>

</template>

<script>
import NavBar from './components/NavBar'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LayOut',
  components: {
    NavBar
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['user', 'token', 'baseApi']),
    ...mapGetters('ws', ['taskRunData']),
    ...mapGetters('alarm', ['alarmType', 'alarmCount'])
  },
  mounted() {
    if (this.token) {
      this.SubscribeTaskStatusChannel()
      this.ChangeAlarmFlag()
    }
  },
  methods: {
    ...mapActions('ws', ['SubscribeTaskStatusChannel', 'Deactivate']),
    ...mapActions('alarm', ['ChangeAlarmFlag']),
    open() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.logout()
        })
        .catch()
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload()
      })
    }
  },
  watch: {
    taskRunData: {
      handler({ code, message }) {
        if (code === 200) {
          this.$notify.success({
            title: '作业运行成功',
            message,
            duration: 5000,
            dangerouslyUseHTMLString: true,
            offset: 100
          })
        } else {
          this.$notify.warning({
            title: '作业运行失败',
            message,
            duration: 5000,
            dangerouslyUseHTMLString: true,
            offset: 100
          })
        }
        this.ChangeAlarmFlag()
      }
    }
  },
  unmounted() {
    this.Deactivate()
  }
}
</script>
<style scoped>
.item {
  margin-top: 0px;
  margin-right: 5px;
}
</style>
