<template>
  <el-pagination
    :current-page="currentPage"
    :page-size="pageSize"
    :total="total"
    background
    layout="total, prev, pager, next, jumper"
    @current-change="handleCurrentChange"
  />
</template>

<script>
export default {
  name: 'PaginationModule',
  props: {
    pageSize: { required: true },
    total: { required: true },
    currentPage: { required: true, default: 1 }
  },
  data() {},
  methods: {
    handleCurrentChange(currentPage) {
      this.$emit('changeCurrentPage', currentPage)
    }
  }
}
</script>

<style scoped></style>
