import { issueSummaryListApi, issueSummaryDeleteApi } from '@/api/project/issue/issueSummary'

export const problemSummaryIndexModuleStore = {
  namespaced: true,
  state: {
    listData: {},
    listLoading: true,
  },
  mutations: {
    SET_LIST_DATA: (state, data) => {
      state.listData = data
    },
    SET_LIST_LOADING: (state, data) => {
      state.listLoading = data
    },
  },
  actions: {
    getListData: async ({ commit, rootGetters }, content) => {
      const { data, code } = await issueSummaryListApi({
        ...content,
        size: rootGetters.pageSize,
      })
      if (code === 200 && data) {
        commit('SET_LIST_DATA', data)
        commit('SET_LIST_LOADING', false)
      } else {
        console.error('Failed to load problemSummary list。')
      }
    },
    deleteIssueSummary: async (obj, id) => {
      return await issueSummaryDeleteApi(id)
    },
  },
  getters: {
    listLoading: state => state.listLoading,
    listData: state => state.listData,
  },
}
