import LogSmallListIndex from './log/log-small-list-index'
import LogErrorListIndex from './log/log-error-list-index'

const routes = [
  {
    path: '/system/operationLog/small',
    name: 'operationLogSmall',
    meta: { title: '操作日志', nested: ['系统管理', '操作日志'] },
    component: LogSmallListIndex,
  },
  {
    path: '/system/operationLog/error',
    name: 'operationLogError',
    meta: { title: '异常日志', nested: ['系统管理', '异常日志'] },
    component: LogErrorListIndex,
  },
]

export default routes
