import { projectNodes } from '@/api/project/project'
import { qualityIssueList } from '@/api/project/issue/qualityIssue'
export const problemListIndexModuleStore = {
  namespaced: true,
  state: {
    treeData: {},
    listData: {},
    treeLoading: true,
    listLoading: true,
  },
  mutations: {
    SET_TREE_DATA: (state, data) => {
      state.treeData = data
    },
    SET_LIST_DATA: (state, data) => {
      state.listData = data
    },
    SET_TREE_LOADING: (state, data) => {
      state.treeLoading = data
    },
    SET_LIST_LOADING: (state, data) => {
      state.listLoading = data
    },
    UPDATE_ISSUE_STATUS_LIST_DATA_BY_INDEX: (state, { index, status }) => {
      state.listData.content[index].issueStatus = status
    },
  },
  actions: {
    getTreeData: async ({ commit }) => {
      const { data, code } = await projectNodes()
      if (code === 200 && data) {
        commit('SET_TREE_DATA', data)
        commit('SET_TREE_LOADING', false)
      } else {
        console.error('Failed to load projectNodes。')
      }
    },
    getListData: async ({ commit, rootGetters }, content) => {
      const { data, code } = await qualityIssueList({
        ...content,
        size: rootGetters.pageSize,
      })
      if (code === 200 && data) {
        commit('SET_LIST_DATA', data)
        commit('SET_LIST_LOADING', false)
      } else {
        console.error('Failed to load problem list。')
      }
    },
  },
  getters: {
    treeLoading: state => state.treeLoading,
    listLoading: state => state.listLoading,
    listData: state => state.listData,
    treeData: state => state.treeData,
  },
}
