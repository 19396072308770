import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import SvgIcon from './components/SvgIcon'
import router from './router/routers'
import axios from 'axios'
import vueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as echarts from 'echarts'
import './assets/js/bootstrap/bootstrap.min.css'
import common from './utils/common.js'
import api from './utils/api.js'
import './router/index'
import 'dayjs/locale/zh-cn'
import 'element-plus/dist/index.css'
import './assets/style/reset.css'
import './assets/style/index.css'
import './assets/icons'
import * as ElIconModules from '@element-plus/icons'
import 'highlight.js/styles/mono-blue.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

const app = createApp(App).use(store).use(router).use(ElementPlus, { locale }).use(vueAxios, axios).use(hljsVuePlugin)
app.config.globalProperties.$publics = common
app.config.globalProperties.$axios = api
app.config.globalProperties.$echarts = echarts
app.component('SvgIcon', SvgIcon)
for (let iconName in ElIconModules) {
  app.component(common.transElIconName(iconName), ElIconModules[iconName])
}
app.config.warnHandler = () => null
app.mount('#app')
