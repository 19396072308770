const baseUrl =
        process.env.VUE_APP_BASE_API === '/' ? '' : process.env.VUE_APP_BASE_API,
    api = {
        state: {
            socketApi: baseUrl + '/websocket',
            baseApi: baseUrl
        },
        getters: {}
    }

export default api
