<template>
  <div ref="editor"></div>
</template>

<script>
import { computed, getCurrentInstance, inject, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from 'vuex'
import { getToken } from '@/utils/auth'
import Editor from 'wangeditor'
export default {
  name: 'RichTextEditor',
  setup(props, { emit, expose }) {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const baseApi = computed(() => store.getters.baseApi)
    let editor = ''
    const initContent = content => {
      editor.txt.clear()
      if (content.html) {
        editor.txt.html(content.html)
      } else if (content.text) {
        editor.txt.text(content.text)
      }
    }
    const richTextEditorConfig = inject('richTextEditorConfig')
    const richTextEditorMenuConfig = inject('richTextEditorMenuConfig')
    const clearContent = () => editor.txt.clear()
    expose({
      initContent,
      clearContent,
    })
    const initEditor = () => {
      editor = new Editor(proxy.$refs.editor)
      editor.config.languageType = [
        'Bash',
        'C',
        'C#',
        'C++',
        'CSS',
        'Java',
        'JavaScript',
        'JSON',
        'TypeScript',
        'Plain text',
        'Html',
        'XML',
        'SQL',
        'Go',
        'Kotlin',
        'Lua',
        'Markdown',
        'PHP',
        'Python',
        'Shell Session',
        'Ruby',
        'typescript',
      ]
      editor.config.menus = richTextEditorMenuConfig.show
        ? [
            'head',
            'bold',
            'fontSize',
            'fontName',
            'italic',
            'underline',
            'strikeThrough',
            'indent',
            'lineHeight',
            'foreColor',
            'backColor',
            'link',
            'list',
            'justify',
            'emoticon',
            'image',
            'table',
            'code',
            'splitLine',
          ]
        : []
      editor.config.fontNames = [
        { name: '黑体', value: '黑体' },
        {
          name: '绝绝字体',
          value: 'Times New Roman',
        },
        '黑体',
        '仿宋',
        '楷体',
        '标楷体',
        '华文仿宋',
        '华文楷体',
        '宋体',
        '微软雅黑',
      ]
      editor.config.height = richTextEditorConfig ? richTextEditorConfig.height : 450
      editor.config.zIndex = 10
      editor.config.placeholder = '请输入内容！'
      editor.config.pasteIgnoreImg = true
      editor.config.showLinkImg = false
      editor.config.uploadImgServer = `${baseApi.value}/api/v1/core/upload/file/local`
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
      editor.config.uploadImgParams = { dir: richTextEditorConfig ? richTextEditorConfig.dir : 'problemSummary' }
      editor.config.uploadImgMaxLength = 1
      editor.config.uploadImgHeaders = {
        Authorization: getToken(),
      }
      editor.config.uploadFileName = 'file'
      editor.config.uploadImgHooks = {
        customInsert: (insertImgFn, result) => insertImgFn(result.data),
      }
      editor.config.onchange = newHtml => emit('updateContent', { text: editor.txt.text(), html: newHtml })
      editor.create()
    }
    onMounted(() => {
      initEditor()
    })
    onBeforeUnmount(() => {
      editor.destroy()
    })
    return {}
  },
}
</script>

<style scoped></style>
